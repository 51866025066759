import styled from 'styled-components'
//import { GiPauseButton } from 'react-icons/gi'
import { AiFillPlusCircle, AiFillPlayCircle } from 'react-icons/ai'
import {RiCloseCircleFill} from 'react-icons/ri'
//import { FaTimes } from "react-icons/fa";


export const MenuContainer = styled.div`
	justify-content: center;
	margin-bottom:20px;
	

`

export const ExploreContainer = styled.div`
	text-align: center;
	justify-content: center;
	z-index:7;
	
`

export const ExploreContainerGroup = styled.div`
	justify-content: center;
	z-index:9;
`

export const ExploreAllButtonContainer = styled.div`
	text-align: left;
	
	display: flex;
	flex-direction: row;
	width:640px;
	flex-wrap: wrap;
	z-index:11;
	
	
	@media screen and (max-width: 700px) {
		width:320px;
	}
`

export const ExploreButtonContainer = styled.div`
	margin: .7em .5em;
	justify-content: center;
	align-items: top;
	text-align: center;
	width: 90px;
	height: 130px;

	@media screen and (max-width: 700px) {
		margin: 0em .5em;
	}
` 

export const ExploreButtonBackground = styled.div`
	width: 90px;
	height: 90px;
	background:rgb(1, 1, 1);
	border-radius: 20px;
`

export const ExploreButton = styled.button`
  color: ${({ isSelected }) => (isSelected ? 'rgb(0, 0, 0, 1)' : 'rgb(256, 256, 256, 1)')};
  border: 0px solid white;
  border-radius: 20px;
  font-size: 12px;
  padding: 8px 20px;

	width: 90px;
	height: 90px;
	opacity:0.8;
	background-image: url(${({ background }) => background});	
  	background-repeat: no-repeat;
  	background-size: cover;
	&:hover{
		opacity:1;
		//color:#ECD4CA;
		transition:0.3s ease-out;
	}
`;

export const MenuT1 = styled.h1`
	color: #fff;
	font-size: 10px;
	margin: 1em 0em;
`

export const PlayIcon = styled(AiFillPlayCircle)`
	margin-left:-40px;
	margin-top:20px;
	margin-right:00px;
	font-size:17px;
	z-index: 3;
	position:absolute;
`

export const PlusIcon = styled(AiFillPlusCircle)`
	margin-left:-61px;
	margin-top:32px;
	margin-right:00px;
	font-size:31px;
	z-index: 3;
	position:absolute;
	color: #fff;
`

export const CreateButton = styled.button`
  color: ${({ isSelected }) => (isSelected ? 'rgb(0, 0, 0, 1)' : 'rgb(256, 256, 256, 1)')};
  border: 0px solid white;
  border-radius: 20px;
  font-size: 12px;
  padding: 8px 20px;
	width: 90px;
	height: 90px;
	opacity:0.5;
	background-image: url(${({ background }) => background});	
  	background-repeat: no-repeat;
  	background-size: cover;
  	z-index: 1;
	&:hover{
		opacity:.65;
		//color:#ECD4CA;
		transition:0.3s ease-out;
	}
`;

export const StyledSlot2 = styled.div` 
	flex: 1;
	align-content: center;
	justify-content: center;
	width: 100%;
	height: 1px;
	background-color: #fff;
	top: 0;
	z-index: 11;
	opacity:0.15;
	margin-bottom:20px;
	margin-top:20px;
	margin-left:0px;
	@media screen and (max-width: 700px) {
		width:320px;
	}
`

export const PreviewContainer = styled.div`
  justify-content:center;

  @media screen and (max-width: 300px) {
		width: 250px;
	}
  
  
`

export const CloseIcon = styled(RiCloseCircleFill)`
	color: #C3C3C3;
	font-size:50px;
	margin-top:-5px;
`

export const PreviewWrapper = styled.div`
	width: 700px;
  	height: 650px;
	border-radius: 30px;
	padding-top:3rem;
	left:40px;

	@media screen and (max-width: 800px) {
		width: 600px;
	}

	@media screen and (max-width: 600px) {
		width: 450px;
	}

	@media screen and (max-width: 500px) {
		width: 400px;
	}

	@media screen and (max-width: 450px) {
		width: 340px;
	}

`
export const PreviewBgd = styled.div`
	background:#000000;	
	//opacity:78%;
	width: 700px;
  	height: 620px;
	border-radius: 30px;
	z-index: 11;
	position:absolute;
	

	

`

export const PreviewMenu = styled.div`

	display:flex;
	flex-direction:column; 
	text-align: left;
	align-items: left;

`

export const PreviewContent= styled.div`
	position:absolute;
	color:#FFFFFF;
	width:600px;
	margin-top:32px;
	margin-left:32px;
	z-index: 12;

	@media screen and (max-width: 800px) {
		width: 500px;
	}
	@media screen and (max-width: 600px) {
		width: 400px;
	}
	@media screen and (max-width: 500px) {
		width: 300px;
	}
	@media screen and (max-width: 450px) {
		width: 280px;
		margin-left:10px;
	}

`

export const PreviewImage = styled.div`
  border: 0px solid white;
  border-radius: 20px;
  font-size: 12px;
	padding: 8px 8px;	
	width: 100px;
	height: 100px;
	opacity:1;
	background-image: url(${({ background }) => background});	
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 1;
	margin-top:5px;
	margin-left:30px;

	@media screen and (max-width: 300px) {
		
		width: 80px;
	height: 80px;
	padding: 2px 2px;	
	margin-left:5px;
	}

	
	
`;

export const PreviewHeader = styled.div`
	display:flex;
	flex-direction:row; 
	margin-left:12px;
	
	
`;

export const PreviewHeaderLeft = styled.div`
	display:flex;
	flex-direction:column; 
	width: 300px;

	@media screen and (max-width: 600px) {
		width: 160px;
	}
	@media screen and (max-width: 300px) {
		width: 140px;
	}

	
`;

export const PreviewHeaderTextBox = styled.div`
	
	font-size:25px;
	text-align:left;
	font-weight:bold;
	font-size: 13px;
	color: #9C9797;
	margin-top:10px;
	margin-bottom:10px;

	@media screen and (max-width: 350px) {
	margin-left:5px;
	

	
`;

export const PreviewMixListContainer = styled.div`
	margin-top:28px;
`;

export const PreviewMixListItem = styled.div`
	margin-top:10px;
	display:flex;
	flex-direction:column; 
	justify-content:space-between;
	font:Inter;
	font-size: 12px;
	color: white;	
	margin-left:12px;
	margin-right:12px;
	margin-bottom:-7px;


	@media screen and (max-width: 450px) {

		margin-left:20px;
		margin-right:20px;
	}

	@media screen and (max-width: 300px) {
		
		margin-left:7px;
		margin-right:7px;
	}
`;

export const PreviewMixListHeader = styled.div`
	margin-top:0px;
	display:flex;
	flex-direction:row; 
	justify-content:space-between;
	font:Inter;
	font-size: 13px;
	color: black;	
	margin-left:12px;
	margin-right:12px;
	margin-bottom:5px;
	text-align: center;
	align-items: center;
	color: #393939;
	font-weight: 500;

	@media screen and (max-width: 450px) {
		font-size:10px;
		margin-left:19px;
		margin-right:15px;
	}

	@media screen and (max-width: 300px) {
		font-size:9px;
		margin-left:7px;
		margin-right:7px;
	}
`;

export const StyledSlot3 = styled.div`
	flex: 1;
	align-content: center;
	justify-content: center;
	//width: 100%;
	height: 0.75px;
	background-color: #9C9797;
	top: 0;
	z-index: 11;
	opacity:0.45;
	margin-bottom:3px;
	margin-top: 3px ;
	margin-left:12px;
	@media screen and (max-width: 450px) {
		margin-left:20px;
		margin-right:20px;
	}

	@media screen and (max-width: 300px) {
		margin-left:7px;
		margin-right:7px;
	}
`;

export const PreviewPlayIcon = styled(AiFillPlayCircle)`
	font-size:60px;
	margin-bottom:16px;
	color:#D34F1D;

	@media screen and (max-width: 300px) {
	font-size:50px;
}
`

export const PreviewHeaderandClose = styled.div`
display:flex;
	flex-direction:row; 
	justify-content:space-between;	
`

export const PreviewPlayButton = styled.button`
	background: transparent;
	height: 60px;
	width: 60px;
	color: white;
	padding: 1rem;
	border: none;
	cursor: pointer;
	font-size: 27px;
	//border: 5px solid white;
	border-radius: 50%;
	padding: 0px;
	margin-bottom:12px;
	margin-left:-3px;

	@media screen and (max-width: 350px) {
	margin-left:2px;
	
`;





export const PlayContainer = styled.div`
	display: flex;
	justify-content: left;
	flex-direction: column;
    padding-bottom:1.5rem;
	#position: absolute;
	z-index: 2;
`

export const BtnSettingsContainer = styled.div`

#margin-right:20px;
z-index: 2;
color: white;

font-family: 'Oxygen', sans-serif;
font-size: 16.5px;
letter-spacing: 1px;
font-Weight: bold;

&:hover{
	opacity:1;
	color:#ECD4CA;
	transition:0.3s ease-out;
}

@media screen and (max-width: 350px) {
	font-size: 11px;
	margin-right:15px;
}

@media screen and (max-width: 300px) {
	font-size: 10px;
	margin-right:15px;
}
`

export const PreviewTitle = styled.div`
	display:flex;
	flex-direction:row; 
	justify-content:space-between;
	color: #393939;
	font-family: 'Oxygen', sans-serif;
	font-size: 24px;
	font-Weight: bold;



	@media screen and (max-width: 350px) {
	font-size: 20px;
	margin-left:5px;
	

	
}
`;

export const PreviewSongTitle = styled.div`
	
	color: #393939;
	font-family: 'Inter';
	font-size: 15px;
	font-weight: 600;
	margin-bottom:5px;

	@media screen and (max-width: 300px) {
		font-size: 12px;

	}
`;

export const PreviewSongArtist = styled.div`
	
	color: #9C9797;
	font-family: 'Inter';
	font-size: 12px;
	margin-bottom:10px;
	font-weight: 400;

	@media screen and (max-width: 300px) {
		font-size: 10px;

	}
	
`;



