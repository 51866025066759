import styled from 'styled-components'
import {Error} from '@styled-icons/boxicons-regular'

export const SmoothiContainer = styled.div`
	z-index: 1;
	overflow:auto;

  
`
export const ForgotContainer = styled.div`
	z-index: 1;
	overflow:auto;
	
`
export const ChoosingContainer = styled.div`
	z-index: 1;
	overflow:auto;
	#position: relative;
	#min-height:calc(100vh - 70px);
	#padding-bottom: 70px;
`

export const ImageBg = styled.div`
	background-color:#EFEBE5;
	min-height:100%;
	background-position: top;
	background-size: cover;
	background-attachment: scroll;
	background-repeat: no-repeat;
	position: fixed;
	top: 0;
	bottom:0;
	left: 0;
	right: 0;
	z-index: -1;	
  
`

export const MenuContainer = styled.div`
	text-align: center;
`

export const SmoothiContent = styled.div`
	display: flex;
  flex-direction: column;
  align-items:center;
  height:100vh;
  justify-content:center;
  margin-top:-100px;

  
`
export const ChoosingContent = styled.div`

  display: flex;

  height:100%;
  justify-content:center;
  margin-top:75px;

  @media screen and (max-height: 700px) {
		margin-top:55px;

     @media screen and (max-height: 600px) {
		margin-top:45px;

  
`

export const ForgotContent = styled.div`

  display: flex;

  height:100%;
  justify-content:center;
  margin-top:75px;

  @media screen and (max-height: 700px) {
		margin-top:55px;

     @media screen and (max-height: 600px) {
		margin-top:45px;

  
`

export const SubSettingsContent = styled.div`
	//position:fixed;
  display: flex;
  height:100%;
  justify-content:center;
  margin-top:10px;  
  //margin-top:130px;

  
  
`



export const SmoothiPlayerContent = styled.div`
	display: flex;
  flex-direction: column;
  align-items:center;
  height:100vh;
  justify-content:center;
  margin-top:-70px;

  @media screen and (max-height: 700px) {
		margin-top:-50px;

  
`

export const TermsContent = styled.div`
	display:flex;
  flex-direction: column;
  align-items:center;
  margin-top:55px;
  width:100%;

  
`

export const SettingsContent = styled.div`

display: flex;

  flex-direction: column;
  align-items:center;
  height:100%;
  justify-content:center;
  margin-top:70px;
  margin-bottom:155px;

 

  
`

export const PreviewContent = styled.div`
display: flex;
  
  
  margin-top:10px;
  margin-left:25px;
	

`

export const ExploreContent = styled.div`
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top:70px;
	
	
`
export const ErrorContent = styled.div`
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top:200px;
	
	
`

export const PageTitleH2 = styled.h2`
	font-size: 19px;
	text-align: left; 
	color: black;
	padding-bottom: 0.0rem;
	margin-left:7px;
	-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`

export const PreviousButtonContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	position: fixed;
	color: #393939;
	font-family: 'Oxygen', sans-serif;
	font-size: 35px;
	letter-spacing: 1.1px;
	z-index:2;

  

	left:33px;
  @media screen and (max-width: 350px) {
	left:10px;
`
export const EmptyHeartPlayerBar = styled.button`
	border: none;
	background-color: Transparent;
	cursor: pointer;
`

export const FullHeartPlayerBar = styled.button`
	border: none;
	background-color: Transparent;
	cursor: pointer;
`

export const ChangePlanBox = styled.div`

	display: flex;
	align-items: center;

	justify-content: space-between;
	
`

export const ChangePlanSubbox = styled.div`
	
`
export const RadioButtonContainer = styled.div`
display: flex;
align-items: left;
justify-content: space-between;
	
`

export const AccountChangePlanButton = styled.button`
  color: #39393;
  border: 1px;
  border-color:#393939;
  border-radius: 30px;
  font-size: 10.3px;
  background: #D34F1D;
  color: white;
  border-color: #D34F1D;

  padding: 10px 0px;
  margin: 1em 0.5em;
  width: 110px;
  margin-top:-5px;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

  cursor: pointer;
 
`

export const ChangePlanButton = styled.button`
  color: #39393;
  border: 1px;
  border-color:#393939;
  border-radius: 30px;
  font-size: 10.3px;
  background: #D34F1D;
  color: white;
  border-color: #D34F1D;

  padding: 10px 0px;
  margin: 1em 1em;
  width: 110px;
  margin-top:-5px;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

  cursor: pointer;
  display: ${({ isChange }) => (isChange ? 'block' : 'none')};
`

export const CancelButton = styled.button`
  color: #39393;
  border: 1px;
  border-color:#393939;
  border-radius: 30px;
  font-size: 10.3px;
  background: #D34F1D;
  color: white;
  border-color: #D34F1D;

  padding: 10px 0px;
  margin: 1em 0.5em;
  width: 110px;
  margin-top:-5px;
  

  cursor: pointer;
`
  
export const ChangePlanText = styled.div`
  color: white;

  font-size: 10px;
  font-weight: 600;
  text-align:center;

  @media screen and (max-width: 300px) {
	font-size: 9px;

`

export const CostText = styled.div`
color: #D34F1D;
font-family: 'Inter';
	font-size: 12px;
	font-weight: 600;
  width:100px;
  margin-top:-18px;
  

  @media screen and (max-width: 450px) {
	font-size: 11px;

`
export const AccountContent = styled.div`
	display: flex;
  flex-direction: column;
  align-items:center;
  
  //margin-top:40px;

`

export const RadioButton = styled.button`
  color: #39393;
  
  border: 3px solid black;
  outline: 2px solid rgb(211, 79, 29, 0.8);
  border-color:#DFD8C8;
  border-radius: 10px;
  background: ${({ isSelected }) => (isSelected ? 'rgb(211, 79, 29, 0.7 )' : 'rgb(0, 0, 0, 0)')};
  color: ${({ isSelected }) => (isSelected ? 'black' : '#393939')};
  border-color: ${({ isSelected }) => (isSelected ? '#DFD8C8' : 'rgba(0,0,0,0)')};
  outline: ${({ isSelected }) => (isSelected ? '2px solid rgb(211, 79, 29, 0.8)' : '2px solid black')};
  margin-right:10px;
  
  
  width: 20px;
  height: 20px;
  `





export const BlockContainer = styled.aside`
position: absolute;
width: 100%;
height: 100%;
background-color:rgba(211,79,29,.75);
backdrop-filter: blur(6px);
display: grid;
align-items: center;
top: 0;
left: 0;
transition: 0.01s ease-in-out;
justify-content: center;

`



export const BlockWrapper = styled.div`
  width: 700px;
	height: 460px;
  border-radius: 30px;
  

  @media screen and (max-width: 800px) {
	  width: 600px;
  }

  @media screen and (max-width: 600px) {
	  width: 450px;
  }

  @media screen and (max-width: 500px) {
	  width: 350px;
  }

  @media screen and (max-width: 450px) {
	  width: 250px;
  }

`
export const BlockBgd = styled.div`
  background:#EFEBE5;	
  width: 700px;
	height: 300px;
  border-radius: 30px;
  z-index: 11;
  position:absolute;
  
  @media screen and (max-width: 800px) {
	  width: 600px;
  }

  @media screen and (max-width: 600px) {
	  width: 450px;
  }

  @media screen and (max-width: 500px) {
	  width: 350px;
  }

  @media screen and (max-width: 450px) {
	  width: 250px;
  }

`



export const BlockContent= styled.div`
  position:absolute;
  color:#FFFFFF;
  width:600px;
  margin-top:32px;
  margin-left:32px;
  z-index: 12;
  display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

  @media screen and (max-width: 800px) {
	  width: 500px;
  }
  @media screen and (max-width: 600px) {
	  width: 400px;
  }
  @media screen and (max-width: 500px) {
	  width: 300px;
  }
  @media screen and (max-width: 450px) {
	  width: 230px;
	  margin-left:10px;
  }
`

export const UpgradeImage = styled.div`
border: 0px solid white;
border-radius: 20px;
font-size: 12px;
  padding: 8px 8px;	
  width: 90px;
  height: 90px;
  opacity:1;
  background-image: url(${({ background }) => background});	
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  
`;

export const BlockHeader = styled.div`
  display:flex;
  flex-direction:row; 
  justify-content:center;
 
`;

export const UpgradeHeaderTextBox = styled.div`
  textAlign: center;
  position:relative;
  
  z-index:999;


	color: #393939;
	font-size: 30px;
	padding-bottom:0.5rem;
	font-family: 'Inter';
	font-weight: 800;
	letter-spacing: 0.3px;
	line-height: 33px; 
	
	

  @media screen and (max-width: 450px) {
	  font-size:20px;
  }
`;

export const VerifyHeaderTextBox = styled.div`
  textAlign: center;
  position:relative;
  
  z-index:999;


	color: #393939;
	font-size: 25px;
	padding-bottom:0.5rem;
	font-family: 'Inter';
	font-weight: 800;
	letter-spacing: 0.3px;
	line-height: 33px; 
	
	

  @media screen and (max-width: 450px) {
	  font-size:20px;
  }
`;

export const CountryBlockTextBox = styled.div`
  textAlign: center;
  position:relative;
  
  z-index:999;


	color: #393939;
	font-size: 22px;
	padding-bottom:0.5rem;
	font-family: 'Inter';
	font-weight: 800;
	letter-spacing: 0.3px;
	line-height: 33px; 
	
	

  @media screen and (max-width: 450px) {
	  font-size:16px;
  }
`;






export const UpgradeMixListItem = styled.div`
  margin-top:0px;
  display:flex;
  flex-direction:row; 
  justify-content:space-between;
  font:Inter;
  font-size: 11px;
  color: white;	
  margin-left:12px;
  margin-right:12px;
  margin-bottom:-10px;
  //padding-bottom: 2px;

  @media screen and (max-width: 450px) {
	  font-size:8px;
	  margin-left:2px;
	  margin-right:2px;
  }
`;

export const BlockTextHeader = styled.div`
  margin-top:22px;
  display:flex;
  flex-direction:row; 
  justify-content:center;
  font:Inter;
  font-size: 13px;
  color: black;	
  font-weight: 500;
  margin-left:12px;
  margin-right:12px;
  margin-bottom:3px;
  text-align: center;
  align-items: center;

  @media screen and (max-width: 450px) {
	  font-size:13px;
	  margin-left:2px;
	  margin-right:15px;
  }
`;

export const StyledSlot3 = styled.div`
  flex: 1;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 0.75px;
  background-color: #9C9797;
  top: 0;
  z-index: 11;
  opacity:0.45;
  margin-bottom:3px;
  margin-top: 3px ;
`;



export const UpgradeHeaderandClose = styled.div`
display:flex;
  flex-direction:row; 
  justify-content:space-between;	
`



export const BtnMaxPremium = styled.button`
margin-top:0px;
color: white;
border:none;
cursor: pointer;
border-radius: 30px;
background:#D34F1D;
padding: 8px 0px;
margin: 0.5em 0.5em;
width: 200px;
font-size: 13px;
  font-weight: 600;
  text-align:center;
 box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

`

export const MaxPremiumText = styled.div`
color: white;

font-size: 13px;
  font-weight: 600;
  text-align:center;

`

export const MaxPremiumSubText = styled.div`
color: white;
margin: 4px;
font-size: 9.5px;
  font-weight: 600;
  text-align:center;



`



export const SkipText = styled.button`
border: none;
background-color: Transparent;
color: rgba(57, 57, 57, 0.55);
margin-top: 24px;
font-size: 14px;
font-weight: 600;
letter-spacing: 0.14px;
cursor: pointer;


`
export const SkipContainer = styled.div`
	display:flex;
  	flex-direction:row; 
  	justify-content:center;


`



export const LogoBg = styled.img`
margin-bottom:10px;

    
`

export const BarLogo = styled.img`


    
`

export const OrangeError = styled(Error)`
  color: #D34F1D;
  width:40%;
  margin-bottom:-10px;
  margin-top:120px
`

export const AccountWrapper = styled.div`
	width: 700px;
	border-radius: 30px;
	padding-top:3rem;
  margin-left:20px;
	//left:40px;
  

	@media screen and (max-width: 800px) {
		width: 600px;
	}

	@media screen and (max-width: 600px) {
		width: 450px;
	}

	@media screen and (max-width: 500px) {
		width: 400px;
	}

	@media screen and (max-width: 450px) {
		width: 340px;
	}

  @media screen and (max-width: 300px) {
		width: 250px;
	}
    @media screen and (max-width: 250px) {
		width: 225px;
	}

`

export const PlansWrapper = styled.div`
	width: 700px;
	border-radius: 30px;
	padding-top:3rem;
	left:40px;
  

	@media screen and (max-width: 800px) {
		width: 600px;
	}

	@media screen and (max-width: 600px) {
		width: 450px;
	}

	@media screen and (max-width: 500px) {
		width: 400px;
	}

	@media screen and (max-width: 450px) {
		width: 380px;
	}

  @media screen and (max-width: 400px) {
		width: 340px;
	}

  @media screen and (max-width: 300px) {
		width: 250px;
	}

`



export const AccountTitle = styled.div`
	display:flex;
	flex-direction:row; 
	justify-content:space-between;
	color: #393939;
	font-family: 'Oxygen', sans-serif;
	font-size: 24px;
	font-Weight: bold;

	@media screen and (max-width: 300px) {
	font-size: 20px;

	
}
`;



export const AccountHeader = styled.div`
	display:flex;
	flex-direction:row; 
	margin-left:12px;

  @media screen and (max-width: 450px) {
	margin-left:17px;
	
	
`;

export const AccountListContainer = styled.div`
	margin-top:28px;
`;

export const AccountContainer = styled.div`
  justify-content:center;

  @media screen and (max-width: 300px) {
		width: 250px;
	}
  
  
`

export const ChangePasswordWrapper = styled.div`
  
display: flex;
flex-direction: column;
align-items:center;
 
`

export const SmoothiLogo = styled.img`
	width:35px;
	
	resize-mode: contain;
	flex: 1;
	margin-left: 15px;
	margin-top:0px;
  margin-bottom:-50px;

	@media screen and (max-width: 500px) {
		margin-left: 15px;
	}
	
	
  `

  export const PasswordChangedWrapper = styled.div`

color:#393939;
padding-Bottom:1rem;
text-Align:center;
font-Weight: 600;
padding-top:3rem;


`

export const ForgotPasswordHeader = styled.div`

color: #393939;
	padding-top: 3rem;
  padding-bottom: 1rem;
	font-size: 18px;
  font-weight:bold;
  text-align:center;
  font-family: 'Inter';
  margin-top:45px;

  @media screen and (max-height: 700px) {
		margin-top:10px;

  @media screen and (max-height: 600px) {
		margin-top:0px;
  
`

export const ForgotPasswordContainer = styled.div`
  z-index: 2;
	width:230px;
	display: flex;
	flex-direction: column;
	text-align: center;
  align-items: center;
  
`


