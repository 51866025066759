//import logo from './logo.svg';
//import './App.css';
import { useEffect, useReducer } from 'react';

import { ApolloClient, HttpLink, InMemoryCache, from, gql } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { onError } from "@apollo/client/link/error";
//import jwt_decode from "jwt-decode"
//import axios from 'axios'
//import { App as AppCap} from '@capacitor/app';

import LogInPage from './pages/logInPage';
import CreateAccountPage from './pages/createAccountPage';
import SmoothiApp from './pages/smoothiAppPage';
import ErrorPage from './pages/errorPage';
//import WrongCountryPage from './pages/wrongCountryPage';
import ForgotPage from './pages/forgotPassword';
import AccountDeletedPage from './pages/accountDeletedPage';
import VerifyEmailPage from './pages/verifyEmailPage';
//import LoadingPage from './pages/loadingPage';
//import { ExploreContent, ImageBg, SmoothiContainer } from './components/AppComponents/appElements';

const VERIFY_TOKEN = gql`
    mutation ($token: String!){
      verifyToken(token: $token){
        success
        errors
      }
    }
  `;


const initialState = {
  page: 'LogIn',
  load: true,
  token: '',
  country: '',
  appError: '',
  lastTokenVerified: 0,
  sessionExpired: false,
  networkError: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return { ...state, country: action.payload.country };
    case 'logOut':
      return { ...state, 
        token: '', 
        page: 'LogIn', 
        sessionExpired: false,
        networkError: false
      };
    case 'logOutExpired':
      return {
        ...state,
        token: '',
        page: 'LogIn',
        sessionExpired: true
      };
    //case 'networkError':
      //return {
        //...state,
        //token: '',
        //page: 'LogIn',
        //sessionExpired: false,
        //networkError: true
      //};
    case 'setToken':
      return {
        ...state,
        token: action.payload.token,
        sessionExpired: false
      };
    case 'setMainMenu':
      return {
        ...state,
        page: 'MainMenu',
        sessionExpired: false
      };
    case 'setCreateAccount':
      return {
        ...state,
        page: 'CreateAccount'
      }
    case 'setForgotPage':
      return {
        ...state,
        page: 'forgotPage'
      }
    case 'LogIn':
      return {
        ...state,
        token: action.payload.token,
        page: 'MainMenu',
        sessionExpired: false
      };
    case 'AccountDeleted':
      return { ...state, 
        token: '', 
        page: 'AccountDeletedPage', 
        sessionExpired: false,
        networkError: false
      };
    default:
      throw new Error();
  }
}

function App() {
  
  const [state, dispatch] = useReducer(reducer, initialState);
  let client

  const logOut = () => {
    localStorage.setItem('LogInToken', '')
    dispatch({ type: "logOut" })
  }

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_URI,
    headers: {
      authorization: state.token ? ("JWT " + state.token) : "", // however you get your token
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors){
      graphQLErrors.forEach(async ({ message, locations, path }) =>{
        if (message === 'You do not have permission to perform this action') {
          if (state.token !== '') {
            localStorage.setItem('LogInToken', '')
            dispatch({ type: "logOutExpired" })
          }
        }
       //console.log(graphQLErrors)
        //console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,)
      }
      );
    }
    //if (networkError) {
      //console.log(`[Network error]: ${networkError}`);
      //localStorage.setItem('LogInToken', '')
      //dispatch({ type: "networkError" })
    //}
  });

  client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache(),
    credentials: 'include',
  });

  //const getLocationData = async () => {
  //  const res = await axios.get('https://geolocation-db.com/json/')
  //  return res.data.country_code
  //}

  //const loadLocationDispatch = () => {
  //  getLocationData()
  //    .then((country) => {
  //      dispatch({ type: "load", payload: { "country": country } })
  //    })
  //};

  const verifyToken = () => {
    return client.mutate({
      mutation: VERIFY_TOKEN,
      variables: { token: localStorage.getItem('LogInToken') }
    })
  }

  const tokenThings = () => {
    verifyToken().then((verifyObject) => {
      if (!verifyObject.data.verifyToken.success) {
        const errorCode = verifyObject.data.verifyToken.errors.nonFieldErrors[0].code
        if (errorCode === "expired_token") {
          //console.log("session expired")
          dispatch({ type: "logOutExpired" })
        }
        else {
          //console.log("no good token")
          localStorage.setItem('LogInToken', '')
          dispatch({ type: "logOut" })
        }
      }
      else if (verifyObject.data.verifyToken.success) {
        //setLastTokenVerified(Date.now())
        //console.log("good token")
        //setToken(localToken)
        dispatch({ type: "setToken", payload: { token: localStorage.getItem('LogInToken') } })
        dispatch({ type: "setMainMenu" })
      }
      //console.log(verifyObject.data.verifyToken)
    })
  }

  //useEffect(() => {
  //  if (state.country === '') {
  //    loadLocationDispatch()
  //  }
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [])

  //useEffect(() => {
  //  if (state.country === 'US'){
  //    if(localStorage.getItem('LogInToken') !== ''){
  //      tokenThings()
  //    }
  //  }
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [state])
  useEffect(() => {
    if(localStorage.getItem('LogInToken') !== ''){
      tokenThings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logIn = (token) => {
    localStorage.setItem('LogInToken', token)
    dispatch({ type: "LogIn", payload: { token: token } })
  }

  const setCreateAccountPage = () => {
    dispatch({ type: 'setCreateAccount' })
  }

  const setForgotPage = () => {
    dispatch({ type: 'setForgotPage' })
  }

  const setAccountDeleted = () => {
    dispatch({ type: 'AccountDeleted' })
  }

  const logOutClick = () => {
    logOut()
  }

  //if (state.country === '' ) {
  //  return(
  //    <div style={{ margin: '0 1rem 0 1rem', paddingTop: 'env(safe-area-inset-top)' }}>
  //      <SmoothiContainer> 
  //        <ImageBg/>
  //        <ExploreContent>
	//				  <LoadingPage/>
  //        </ExploreContent>
	//			</SmoothiContainer>
  //    </div>
  //  )
  //}
  //else if (state.country !== 'US') {
  //  return(
  //    <WrongCountryPage appError={"Smoothi is not yet available in your country!"}/>
  //  )
  //}

  switch (state.page) {
    case 'LogIn':
      return(
        <div style={{ margin: '0 1rem 0 1rem', paddingTop: 'env(safe-area-inset-top)' }}>
          <ApolloProvider client={client}>
            <LogInPage 
            logIn={logIn}
            setCreateAccountPage={setCreateAccountPage}
            setForgotPage={setForgotPage}
            appError={state.appError}
            sessionExpired={state.sessionExpired}
            networkError={state.networkError}/>
          </ApolloProvider>
        </div>
      )
    case 'CreateAccount':
      return(
        <div style={{ margin: '0 1rem 0 1rem', paddingTop: 'env(safe-area-inset-top)' }}>
          <ApolloProvider client={client}>
            <CreateAccountPage logOut={logOutClick}/>
          </ApolloProvider>
        </div>
      )
    case 'forgotPage':
      return(
        <div style={{ margin: '0 1rem 0 1rem', paddingTop: 'env(safe-area-inset-top)' }}>
          <ApolloProvider client={client}>
            <ForgotPage logOut={logOutClick}/>
          </ApolloProvider>
        </div>
      )
    case 'verifyPage':
      return(
        <div style={{ margin: '0 1rem 0 1rem', paddingTop: 'env(safe-area-inset-top)' }}>
          <ApolloProvider client={client}>
            <VerifyEmailPage logOut={logOutClick}/>
          </ApolloProvider>
        </div>
      )
    case 'MainMenu':
      return(
        <div style={{ margin: '0 1rem 0 1rem', paddingTop: 'env(safe-area-inset-top)' }}>
          <ApolloProvider client={client}>
            <SmoothiApp logOutClick={logOutClick} setAccountDeleted={setAccountDeleted}/>
          </ApolloProvider>
        </div>
      )
    case 'AccountDeletedPage':
      return(
        <div style={{ margin: '0 1rem 0 1rem', paddingTop: 'env(safe-area-inset-top)' }}>
          <AccountDeletedPage/>
        </div>
      )
    default:
      return (
        <div style={{ margin: '0 1rem 0 1rem', paddingTop: 'env(safe-area-inset-top)' }}>
          <ErrorPage appError={state.appError}/>
        </div>
      );
  }
}

export default App;