import styled from 'styled-components'
import {Link} from 'react-router-dom'
import { AiFillPlusCircle, AiFillHome } from 'react-icons/ai'
//import {CiHome, CiSettings } from 'react-icons/ci'

import { Settings } from '@styled-icons/fluentui-system-filled'


export const FooterContainer = styled.footer`
	background-color: #353535;
	width: 100%;
	height:92px;
	//height:120 //Samsung
	left: 0;
	bottom: 0;
	margin:0;
	padding:0;
	position: fixed;
	box-sizing: border-box;
	border-top-left-radius:23px;
	border-top-right-radius:23px;
	z-index: 1000;
`

export const FooterWrap = styled.div`
	display: flex;
	justify-content:space-evenly;
	margin-top: 5px;
`

export const FooterLinksContainer = styled.div`
	display: flex;
	justify-content: center;
`

export const FooterLinksWrapper = styled.div`
	display: flex;
`

export const FooterLinkItems = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	text-align: left;
	width: 200px;
	box-sizing: border-box;
	color: #fff;
`

export const FooterLink = styled(Link)`
	color:#fff;
	text-decoration:none;
	font-size:33px;

	&:hover{
		color:#ECD4CA;
		transition:0.3s ease-out;
	}

	@media screen and (max-width:768px) {
		font-size: 24px;
	}

	@media screen and (max-width: 480px) {
		font-size: 20px;
	}
`;


export const SocialMedia = styled.section`
	width: 100%;
`

export const SocialMediaWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 40px;
`

export const IconWrap = styled.h1`
	color: #fff;
	font-size: 10px;
	display: flex;
	flex-direction: column;
	align-items:center;
	cursor: pointer;
`

export const HomeIcon = styled(AiFillHome)`
	
	color: #353535;
	opacity: ${({ current }) => ((current === 'mainMenu' || current === 'preview') ? '100%' : '60%')};	
	background:white;
	border-radius: 50%;
  	padding: 5px;
  	font-size: 24px;
	cursor: pointer;
`

export const SettingsIcon = styled(Settings)`

	color: #353535;
	opacity: ${({ current }) => (current === 'settings' ? '100%' : '60%')};	
	background:white;
	border-radius: 50%;
  padding: 5px;
  font-size: 24px;
  width:24px;
	cursor: pointer;
	margin-top:-1.5px;
	margin-bottom:2px;
`

export const CreateIcon = styled(AiFillPlusCircle)`
	font-size:39px; 
	margin-top:-3px;//Due to Ai vs Ci
	color: white;
	opacity: ${({ current }) => (current === 'choosing' ? '100%' : '60%')};	
	
`

export const SocialEmail = styled.a`
	color: #fff;
	justify-self: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 0.75rem;
	display: flex;
	align-items: center;

	&:hover{
		color: #ECD4CA;
		transition: 0.3s ease-out;
	}

	@media screen and (max-width:768px) {
		font-size: .6rem;
	}

	@media screen and (max-width: 480px) {
		font-size: .5rem;
	}
`

export const RightLink = styled(Link)`
	color: #fff;
	justify-self: center;
	cursor: pointer;
	text-decoration: none;
	font-size: 0.75rem;
	display: flex;
	align-items: center;
	//margin-bottom: 60px;
	//padding: 0px 20px;
	//border:solid;

	&:hover{
		color: #ECD4CA;
		transition: 0.3s ease-out;
	}

	@media screen and (max-width:768px) {
		font-size: .6rem;
	}

	@media screen and (max-width: 480px) {
		font-size: .5rem;
	}
`

export const WebsiteRights = styled.small`
	color: #fff;
	font-size: 0.75rem;

	@media screen and (max-width:768px) {
		font-size: .6rem;
	}

	@media screen and (max-width: 480px) {
		font-size: .5rem;
	}
`

export const SocialIcons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 170px;
	color: #fff;
	margin-right: 15px;

	@media screen and (max-width:768px) {
		width: 160px;
	}

	@media screen and (max-width: 480px) {
		width: 140px;
	}
`

export const SocialIconLink = styled.a`
	color: #fff;
	font-size: 24px;
`

export const StyledSlot2 = styled.div`
	flex: 1;
	align-content: center;
	justify-content: center;
	height: 1px;
	background-color: #fff;
	top: 0;
	z-index: 11;
	opacity: 0.15;
	margin-left: 30px; 
	margin-right: 30px; 
`;

export const HomeTextWrap = styled.div`
	font-size: 10px;
	margin-top:1.5px;
	opacity: ${({ current }) => ((current === 'mainMenu'|| current === 'preview') ? '100%' : '60%')};
`
export const SettingsTextWrap = styled.div`
	font-size: 10px;
	//margin-top:1.5px;
	opacity: ${({ current }) => (current === 'settings' ? '100%' : '60%')};	
`

export const CreateTextWrap = styled.div`
	font-size: 10px;
	margin-top:-.3px;
	opacity: ${({ current }) => (current === 'choosing' ? '100%' : '60%')};	
	
`