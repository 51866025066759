import { SignInButton, LogoBg, LoginHeader, BtnForgot, BtnSignInContainer, BtnCreate, Input, LogInContainer } from "./logInElements"
import Logo from '../../images/smoothi-logo-circle.svg'
import Phrase from '../../images/Hit Blend_ Blend Hits.svg'

function LogInSection({username, handleUsernameChange, password, handlePasswordChange, setForgotPage, handleSignIn, setCreateAccountPage, credentialsError, message}) {
  return (
    <div>
      <LogInContainer>
      <LogoBg src={Logo}/>
        <LoginHeader src={Phrase}/>
        
        <Input
          type="email"
          autoComplete="off"
          spellCheck="false"
          placeholder={'Username'}
          value={username}
          onChange={handleUsernameChange} />
			  <Input type="password" placeholder={'Password'} value={password} onChange={handlePasswordChange} />
      
        <BtnForgot style={{cursor: 'pointer'}}> 
          <p style={{ textAlign: 'center' }} onClick={setForgotPage}>Forget your password? </p>
        </BtnForgot>
        
        <BtnSignInContainer>
          <SignInButton onClick={handleSignIn} style={{cursor: 'pointer'}}>Sign in </SignInButton>
        </BtnSignInContainer>
      
        <BtnCreate onClick={setCreateAccountPage} style={{cursor: 'pointer'}}>Create an account</BtnCreate>
        
        <p>{credentialsError ? 'Please, Enter valid credentials' : ''}</p>
        <BtnForgot style={{ cursor: 'pointer' }}>
          <p style={{ textAlign: 'center' }}>{message}</p>
        </BtnForgot>
			</LogInContainer>
    </div>
  )
}

export default LogInSection