import { useContext, useState } from 'react';
import { AccountTitle, AccountWrapper, AccountContent, SubSettingsContent, ChangePlanText, PreviousButtonContainer, ChangePlanBox,ChangePlanSubbox,AccountChangePlanButton } from '../components/AppComponents/appElements';
import { FaAngleLeft } from 'react-icons/fa';
import { PreviewMenu,PreviewHeader,PreviewHeaderLeft,PreviewMixListItem, PreviewSongTitle, PreviewSongArtist,StyledSlot3,PreviewMixListContainer} from '../components/PreviewSection/PreviewElements';
import { UserContext } from '../context/UserContext';
import ChangePasswordPage from './changePasswordPage';
import DeleteAccountPage from './deleteAccountPage';
import PlansPage from './plansPage';

function AccountButton({text, buttonAction}) {

	return(
		<AccountChangePlanButton onClick={buttonAction}>
			<ChangePlanText>
				{text}
			</ChangePlanText>
		</AccountChangePlanButton>
	)
}

function AccountItem({title, text, button, buttonText, buttonAction}) {
	return(
		<PreviewMixListItem>
			<ChangePlanBox>
				<ChangePlanSubbox>
					<PreviewSongTitle>
					{title}
					</PreviewSongTitle>
					<PreviewSongArtist>
					{text}
					</PreviewSongArtist>
				</ChangePlanSubbox>
				{button ? <AccountButton text={buttonText} buttonAction={buttonAction}/>:''}
			</ChangePlanBox>
		</PreviewMixListItem>
	)
}

function AccountMenu({goToPlansPage, changePasswordClick, deleteAccountClick, username, email, accountType, subscriptionEnding}) {
	let subscriptionText = accountType
	if (subscriptionEnding) {
		subscriptionText = subscriptionText + ' subscription ending soon'
	}

	return(
		<AccountContent>
			<AccountWrapper>
				<PreviewMenu>
					<PreviewHeader>
    			  <PreviewHeaderLeft>
	  					<AccountTitle>Account</AccountTitle>
						</PreviewHeaderLeft>
					</PreviewHeader>
			
					<PreviewMixListContainer>
						<AccountItem title={'Username'} text={username}/>
						<StyledSlot3/>
						<AccountItem title={'Email'} text={email}/>
						<StyledSlot3/>
						<AccountItem title={'Account Type'} text={subscriptionText} button={true} buttonText={'Change Your Plan'} buttonAction={goToPlansPage} />
						<StyledSlot3/>
						<AccountItem title={'Change Password'} button={true} buttonText={'Change Password'} buttonAction={changePasswordClick}/>
						<StyledSlot3/>
						<AccountItem title={'Delete Account'} button={true} buttonText={'Delete Account'} buttonAction={deleteAccountClick}/>
					</PreviewMixListContainer>

				</PreviewMenu>
			</AccountWrapper>
		</AccountContent>
	)
}

function AccountPage({backToSettingsMenu}){
	const {username, email, accountType, subscriptionEnding, refetchUserData} = useContext(UserContext)

	const [page, setPage] = useState('Account')
	const [clickedChangePlan, setClickedChangePlan] = useState(false)

	const backToAccountMenu = () => {
		if (clickedChangePlan) {
			refetchUserData()
		}
		setPage('Account')
	}

	const plansClick = () => {
		setClickedChangePlan(false)
		setPage('PlansPage')
	}

	const changePasswordClick = () => {
		setPage('PasswordPage')
	}

	const deleteAccountClick = () => {
		setPage('DeletePage')
	}

	if (page === 'Account'){
		return (		
			<SubSettingsContent>
				<PreviousButtonContainer>
					<FaAngleLeft onClick={backToSettingsMenu} />
				</PreviousButtonContainer>
				<AccountMenu backToSettingsMenu={backToSettingsMenu} 
					goToPlansPage={plansClick}
					changePasswordClick={changePasswordClick}
					deleteAccountClick={deleteAccountClick}
					username={username} 
					email={email} 
					accountType={accountType} 
					subscriptionEnding={subscriptionEnding}/>
			</SubSettingsContent>
		)
	}
	if (page === 'PlansPage'){
		return(
			<SubSettingsContent>
				<PreviousButtonContainer style={{cursor: 'pointer'}}>
					<FaAngleLeft onClick={backToAccountMenu}/>
				</PreviousButtonContainer>
				<PlansPage backToSettingsMenu={backToSettingsMenu} subscriptionEnding={subscriptionEnding} accountType={accountType} setClickedChangePlan={setClickedChangePlan}/>
			</SubSettingsContent>
		)
	}
	if (page === 'PasswordPage'){
		return (
			<SubSettingsContent>
						<PreviousButtonContainer style={{cursor: 'pointer'}}>
							<FaAngleLeft onClick={backToAccountMenu} />
						</PreviousButtonContainer>
						<ChangePasswordPage/>
			</SubSettingsContent>
		)
	}
	if (page === 'DeletePage'){
		return (
			<SubSettingsContent>
				<PreviousButtonContainer style={{cursor: 'pointer'}}>
					<FaAngleLeft onClick={backToAccountMenu} />
				</PreviousButtonContainer>
				<DeleteAccountPage/>
			</SubSettingsContent>
		)
	}
	
}

export default AccountPage