
import { SkipContainer,SkipText, BlockContent,BlockContainer, BlockWrapper, BlockBgd, BlockHeader, CountryBlockTextBox} from '../components/AppComponents/appElements';

import {World} from '@styled-icons/boxicons-regular'



function WrongCountryPage ({appError, logOutClick}) {
	return (
		<BlockContainer >

			<BlockWrapper>
				<BlockBgd style={{height:"250px"}} />
	
					<BlockContent>
					<World style={{color:"#D34F1D", width:"80px"}} />
					
						<BlockHeader>
							
							
							<CountryBlockTextBox>
							{appError}
								
							</CountryBlockTextBox>
							
						</BlockHeader>

						
						<SkipContainer>
						<SkipText style={{marginTop:"14px"}} 
						onClick={logOutClick}>Log out
						</SkipText>
						</SkipContainer>
					</BlockContent>
			</BlockWrapper>
		</BlockContainer>
		
	)
}

export default WrongCountryPage