import { useParams, Link } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import { FiArrowRightCircle } from 'react-icons/fi'
import Navbar from '../components/Navbar';
import { ImageBg, SmoothiContainer, SmoothiContent } from '../components/AppComponents/appElements'; 
import { BtnCreate } from "../components/LogInSection/logInElements";

function ResetPasswordForm({token}) {
	return(
		<div>{token}</div>
	)
}

function ResetPasswordPage() {
    let params = useParams();
    const client = new ApolloClient({
      uri: process.env.REACT_APP_URI,
      cache: new InMemoryCache()
    });
    return (
      <ApolloProvider client={client}>
        <Navbar />
        <SmoothiContainer>
          <ImageBg />
          <SmoothiContent>
            <ResetPasswordForm token={params.token}/>
            <Link to="/" style={{ textDecoration:"none" }}>
              <BtnCreate style={{ cursor: 'pointer' }}>
                Go to App <FiArrowRightCircle style={{ fontSize: "38px", margin: "7px", cursor: 'pointer' }} /> 
              </BtnCreate>
            </Link>
          </SmoothiContent>
        </SmoothiContainer>
      </ApolloProvider>
    )
  }

export default ResetPasswordPage