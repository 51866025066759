import { useState, useRef, useEffect } from 'react'
import { gql, useMutation} from '@apollo/client';

import SimpleReactValidator from 'simple-react-validator'; //https://github.com/dockwa/simple-react-validator
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
//import { Device } from '@capacitor/device';
//import { DatePicker } from '@capacitor-community/date-picker';
import { ExploreContent,ImageBg, SmoothiContainer, SmoothiContent, TermsContent } from '../components/AppComponents/appElements';
import { BirthdayText2,BirthdayButtonWrapper,BirthdayInput, BirthdayButtonContainer, BtnReturn, BtnSignInContainer, BtnCreate, CreateAccountText,TermsContainer ,BtnCreateAccountContainer,CreateAccountButton,CreateAccountHeader, StyledSlotLogIn,  Input, BdayContainer, AcceptContainer, SelectionContainer, BtnTerms, BtnTerms2, PButton } from "../components/LogInSection/logInElements"
import {PreviousButtonContainer} from "../components/AppComponents/appElements";
import { FaAngleLeft} from 'react-icons/fa'
import LoadingPage from './loadingPage';
import { FiArrowRightCircle } from 'react-icons/fi';
//import { Capacitor } from '@capacitor/core';


const CREATE_ACCOUNT = gql`
  mutation CreateAccount($username: String!, $email: String!, $password: String!, $birthday: String!) {
    register(username: $username, email: $email, password1: $password, password2: $password, birthday: $birthday){
      success
      errors
    }
  }
`;

//const months = [
//	"January", 
//	"February", 
//	"March", 
//	"April", 
//	"May", 
//	"June", 
//	"July", 
//	"August", 
//	"September", 
//	"October",
//	"November",
//	"December"
//];

//async function digestMessage(message) {
//	const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
//	const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
//	const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
//	const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
//	return hashHex;
//}

const isOldEnough = (birthday_day, minimumAge) => {
	const today = dayjs().startOf('day')
	const yearDiff = today.year() - birthday_day.year()
	const monthDiff = today.month() - birthday_day.month()
	const dayDiff = today.date() - birthday_day.date()
	if (yearDiff > minimumAge) {
		return true
	}
	else if (yearDiff > minimumAge - 1) {
		if (monthDiff > 0) {
			return true
		}
		else if (monthDiff === 0) {
			if (dayDiff >= 0) {
				return true
			}
			else {
				return false
			}
		}
		else {
			return false
		}
	}
	else {
		return false
	}
}

//function DropDown({ title, setTitle, menuType, open, setOpen }) {
//	let list = []
//	let currentYear = new Date().getFullYear()
//	if (menuType === 'day') {
//		for (let i = 1; i < 32; i++) {
//			list.push(
//				<li style={{textIndent: "1em",textAlign:"left",width:"90px",listStyle: "none",backgroundColor: (i === title) ? "pink":"transparent"}} 
//					key={i} 
//					onClick={() => {
//				setTitle(i)
//				setOpen(false)
//			}}>{i}</li>)
//		}
//	}
//	else if (menuType === 'year') {
//		for (let i = 0; i < 110; i++) {
//			const year = currentYear - i
//			list.push(
//				<li style={{ textIndent: "1em",textAlign:"left",width:"90px",listStyle: "none", backgroundColor: (year === title) ? "pink" : "transparent" }} 
//				key={i} onClick={() => {
//				setTitle(year)
//				setOpen(false)
//			}}>{year}</li>)
//		}
//	}
//	else if (menuType === 'month') {
//		list = months.map((value, index) => {
//			return (
//				<li style={{ textIndent: "1em",textAlign:"left",width:"103px", listStyle: "none",backgroundColor: (value === title) ? "pink" : "transparent"}} 
//				key={index} onClick={() => {
//					setTitle(value)
//					setOpen(false)
//				}}>{value}</li>
//			)
//		})
//	}
//
//	return (
//		<BtnBday style={{cursor: 'pointer'}} onClick={() => { setOpen(!open) }}>
//		<li style={{ display: "inline-block" }}>
//			<button style={{ cursor: 'pointer',color: "black", backgroundColor: "transparent", border: "none", padding:"0px 0px" }} >{title}<div style={{ position: "relative", bottom: "-2px", display: "inline-block" }}><MdOutlineKeyboardArrowDown/></div></button>
//			<div style={{
//				display: open ? "block" : "none", position: "left", borderRadius: "10px",marginTop:"-27px",marginLeft:"-36px",backgroundColor:"rgba(236, 236, 236, 0.6)",backdropFilter: "blur(.5rem)", maxHeight: "100px",
//				overflow: "scroll", zIndex: 3
//			}}>
//				{open ? <ul style={{ float: "none", display: "block", textDecoration: "none", padding: "10px 0px", color: "black" }} > {list}</ul > : ""}
//			</div>
//		</li>
//		</BtnBday>
//	)
//}

//function PhoneDateComponent({setBirthday}) {
//	const [clicked, setClicked] = useState(false)
//	const [rawDate, setRawDate] = useState(dayjs().format('MM/DD/YYYY'))
//	const selectedTheme = 'light';
//
//	useEffect(()=>{
//		if (rawDate === '' || rawDate === null || rawDate === undefined){
//			setRawDate(dayjs().format('MM/DD/YYYY'))
//		}
//	})
//	
//	useEffect(()=>{
//		const day = rawDate.split("/")[1]
//		const month = rawDate.split("/")[0]
//		const year = rawDate.split("/")[2]
//		setBirthday(year + '-' + month + '-' + day)
//	})
//
//	const buttonClick =	() => { 
//		setClicked(true)
//		DatePicker.present({
//			mode: 'date',
//			theme: selectedTheme,
//			ios: {
//				format: 'MM/dd/yyyy',
//			},
//			android: {
//				format: 'MM/dd/yyyy',
//			},
//		}).then(date => {
//			if (!(date.value === '' || date.value === null || date.value === undefined)){
//				setRawDate(date.value)
//			}
//		});
//	}
//	return (
//		<ButtonBdayPhone style={{cursor: 'pointer'}} onClick={buttonClick}>
//			<PButton>{clicked ? rawDate: "Birthday"}</PButton>
//		</ButtonBdayPhone>
//	)
//}

//function DateComponent({ setBirthday, platform }) {
//	const [day, setDay] = useState('day')
//	const [month, setMonth] = useState('month')
//	const [year, setYear] = useState('year')
//	const [dayOpen, setDayOpen] = useState(false)
//	const [monthOpen, setMonthOpen] = useState(false)
//	const [yearOpen, setYearOpen] = useState(false)
//	let ref = useRef()
//
//	useEffect(() => {
//		let year_value = 'XXXX'
//		let month_value = 'XX'
//		let day_value = 'XX'
//		if (!(day === 'day' || month==='month' || year === 'year')) {
//			year_value = year
//			month_value = ("0" + (months.indexOf(month) + 1)).slice(-2)
//			day_value = ("0" + day).slice(-2)
//		}
//		setBirthday(year_value + '-' + month_value + '-' + day_value )
//	},[day, month, year, setBirthday])
//
//	useEffect(() => {
//		const handler = (event) => {
//			if (dayOpen && ref.current && !ref.current.contains(event.target)) {
//				setDayOpen(false);
//			}
//			if (monthOpen && ref.current && !ref.current.contains(event.target)) {
//				setMonthOpen(false);
//			}
//			if (yearOpen && ref.current && !ref.current.contains(event.target)) {
//				setYearOpen(false);
//			}
//		};
//		document.addEventListener("mousedown", handler);
//		document.addEventListener("touchstart", handler);
//		return () => {
//			// Cleanup the event listener
//			document.removeEventListener("mousedown", handler);
//			document.removeEventListener("touchstart", handler);
//		};
//	}, [dayOpen, monthOpen, yearOpen]);
//
//	const dayOpenClick = () => {
//		if (!dayOpen) {
//			setMonthOpen(false)
//			setYearOpen(false)
//		}
//		setDayOpen(!dayOpen)
//	}
//
//	const monthOpenClick = () => {
//		if (!monthOpen) {
//			setDayOpen(false)
//			setYearOpen(false)
//		}
//		setMonthOpen(!monthOpen)
//	}
//
//	const yearOpenClick = () => {
//		if (!yearOpen) {
//			setDayOpen(false)
//			setMonthOpen(false)
//		}
//		setYearOpen(!yearOpen)
//	}
//	if (platform === 'web') {
//		return (
//			<div ref={ref}>
//				<BirthdayText>
//				Birthday
//				</BirthdayText>
//				<BdayContainer>
//					<DropDown click={dayOpenClick} title={day} open={dayOpen} setOpen={dayOpenClick} menuType={'day'} setTitle={setDay}/>
//					<DropDown click={monthOpenClick} title={month} open={monthOpen} setOpen={monthOpenClick} menuType={'month'} setTitle={setMonth}/>
//					<DropDown click={yearOpenClick} title={year} open={yearOpen} setOpen={yearOpenClick} menuType={'year'} setTitle={setYear}/>
//				</BdayContainer>
//			</div>
//		)
//	}
//	else if (platform === 'ios' || platform === 'android'){
//		return <PhoneDateComponent setBirthday={setBirthday}/>
//	}
//}

function CreateAccount({ goToCreated, setUserData, logOut }) {
	//const [platform, setPlatform] = useState('')
	//const [os, setOS] = useState('')
	//const [secret, setSecret] = useState('')
	const [email, setEmail] = useState('')
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [birthday, setBirthday] = useState('')
	const [oldEnough, setOldEnough] = useState(false)
	const [allValid, setAllValid] = useState(false)
	const [currentClick, setCurrentClick] = useState(false)

	const simpleValidator = useRef(new SimpleReactValidator())

	const birthday_day = dayjs(birthday, 'YYYY-MM-DD', true)

	const minimumAge = 18
	//const minimumAgeMessage = "Minimum age is " + minimumAge

	//const handleSecretChange = (event) => {
	//	setSecret(event.target.value)
	//}

	const handleEmailChange = (event) => {
		setEmail(event.target.value)
	}

	const handleUsernameChange = (event) => {
		setUsername(event.target.value)
	}

	const handlePasswordChange = (event) => {
		setPassword(event.target.value)
	}

	const handleBirthdayChange = (event) => {
		setBirthday(event.target.value)
	}

	const handleCreateAccount = async () => {
		if (allValid) {
			goToCreated()
		}
	}

	//useEffect(()=>{
	//	Device.getInfo().then((info)=>{
	//		setPlatform(info.platform)
	//		setOS(info.operatingSystem)
	//	})
	//})

	useEffect(()=>{
		dayjs.extend(customParseFormat)
	})
	
	useEffect(() => {
		if (birthday_day.isValid()) {
			if (isOldEnough(birthday_day, minimumAge)) {
				setOldEnough(true)
			}
			else {
				setOldEnough(false)
			}
		}
		setAllValid( simpleValidator.current.allValid() && birthday_day.isValid() && oldEnough)
		//if (secret) {
		//	digestMessage(secret)
		//		.then(digestHex => {
		//			if (digestHex === process.env.REACT_APP_SECRET) {
		//				setAllValid( simpleValidator.current.allValid() && birthday_day.isValid() && oldEnough)
		//			}
		//		});
		
		
	}, [email, username, password, birthday, birthday_day, oldEnough])

	useEffect(()=>{
		//const secret_bool = (secret === process.env.REACT_APP_SECRET)
		if (allValid) {
			setUserData({
				'email': email,
				'username': username,
				'password': password,
				'birthday': birthday
			})
		}
	}, [email, username, password, birthday, allValid, setUserData])

	const clickedChoice = () => {
		
		setCurrentClick(true)
	}
		
	
		
	return(
		<SmoothiContainer>
			<ImageBg/>
			<ExploreContent>
				<PreviousButtonContainer onClick={logOut} style={{cursor: 'pointer'}}>   
					<FaAngleLeft/>  
				</PreviousButtonContainer>
				<CreateAccountHeader >
					Create your account
				</CreateAccountHeader>
				
				<SelectionContainer>
					<Input style={{margin:"0.3px"}} type="email" 
						placeholder={'Email'} 
						value={email} 
						onChange={handleEmailChange} 
						onBlur={() => simpleValidator.current.showMessageFor('email')}/>
					<PButton> 
						{simpleValidator.current.message('email', email, 'required|email') ? simpleValidator.current.message('email', email, 'required|email').props.children:""} 
					</PButton>
					<Input style={{margin:"0.3px"}}
						type="email" 
						placeholder={'Username'} 
						value={username} 
						onChange={handleUsernameChange} 
						onBlur={() => simpleValidator.current.showMessageFor('username')}/>
					<PButton> 
						{simpleValidator.current.message('username', username, 'required|min:5|max:32|alpha_num') ? simpleValidator.current.message('username', username, 'required|min:5|max:32|alpha_num').props.children:""}
					</PButton>
					<Input style={{margin:"0.3px"}}
						type="password" 
						placeholder={'Password'} 
						value={password} 
						onChange={handlePasswordChange} 
						onBlur={() => simpleValidator.current.showMessageFor('password')}/>
					<PButton> 
						{simpleValidator.current.message('password', password, 'required|min:8') ? simpleValidator.current.message('password', password, 'required|min:8').props.children:""}
					</PButton>

					<BirthdayButtonWrapper>
						<BirthdayText2>Birthday</BirthdayText2>
						<BirthdayButtonContainer >
							<BirthdayInput style={{ color:"#393939", opacity:"80%", textAlign:"center"}}
								type="date"
								isSelected={currentClick}
								onClick={()=>clickedChoice()}
								placeholder="No date found"
								onChange={handleBirthdayChange}
								/>
						</BirthdayButtonContainer>
					</BirthdayButtonWrapper>

					<BtnCreateAccountContainer>
						<CreateAccountButton onClick={handleCreateAccount} style={{cursor: 'pointer'}}>Done </CreateAccountButton>
					</BtnCreateAccountContainer>
					<TermsContainer >By signing up, you accept and agree to Smoothi's Terms of Use and Privacy Policy.</TermsContainer>
					<StyledSlotLogIn/>
				</SelectionContainer>
			</ExploreContent>
	</SmoothiContainer>
)
		
}

function AccountNotCreated({message, logOut}) {
	return (
		<SmoothiContainer>
			<ImageBg/>
			<SmoothiContent>
				<CreateAccountHeader>
					Account Not Created
				</CreateAccountHeader>
				<CreateAccountText>
					{message}
				</CreateAccountText>
				<BdayContainer>
					<BtnReturn onClick={logOut}>Take me to Smoothi!</BtnReturn>
				</BdayContainer>
			</SmoothiContent>
		</SmoothiContainer>
	)
}

function AccountCreated({ logOut, userData  }) {
	const email = userData.email
	const username = userData.username
	const password = userData.password
	const birthday = userData.birthday

	const [createAccountFunction, { data, loading, error }] = useMutation(CREATE_ACCOUNT, { errorPolicy: 'all' })
	const [networkError, setNetworkError] = useState(false)

	useEffect(()=>{
		const createAccount = async () => {
			try {
				await createAccountFunction({ variables: { username: username, email: email, password: password, birthday: birthday } })
			}
			catch (createError) {
				setNetworkError(true)
			}
		}
		createAccount()
	}, [createAccountFunction, email, password, username, birthday])

	if (networkError) {
		return (
			<SmoothiContainer>
				<ImageBg/>
				<SmoothiContent>
					<CreateAccountHeader>
						Account Not Created
					</CreateAccountHeader>
					<CreateAccountText>
						Network Error
					</CreateAccountText>
					<BtnSignInContainer>
						<BtnCreate style={{ cursor: 'pointer' }} onClick={logOut}>Go To Main Page<FiArrowRightCircle style={{fontSize: "38px", marginRight:"-5px",margin:"7px", cursor: 'pointer'}}/> </BtnCreate>
					</BtnSignInContainer>
				</SmoothiContent>
			</SmoothiContainer>
		)
	}
	else if (!networkError) {
		if (loading) {
			return(
				<LoadingPage/>
			)
		}
		if (error) {
			return (
				<SmoothiContainer>
					<ImageBg/>
					<SmoothiContent>
						<CreateAccountHeader>
							Error creating account.
						</CreateAccountHeader>
						<BtnSignInContainer>
							<BtnCreate style={{ cursor: 'pointer' }} onClick={logOut}>Go To Main Page<FiArrowRightCircle style={{fontSize: "38px", marginRight:"-5px",margin:"7px", cursor: 'pointer'}}/> </BtnCreate>
						</BtnSignInContainer>
					</SmoothiContent>
				</SmoothiContainer>
			)
		}
	}
	if (!networkError && data){
		if (data.register){
			if (data.register.success) {
				return (
					<SmoothiContainer>
						<ImageBg/>
						<SmoothiContent>
							<CreateAccountHeader>
								Account Created
							</CreateAccountHeader>
							<CreateAccountText>
								Thanks, {username}!
							</CreateAccountText>
							<CreateAccountText>
								Please verify your account with the link sent to
							</CreateAccountText>
							<p style={{ textAlign: "center", color: "grey", fontSize:"13px" }}>{email}</p>
							<BdayContainer>
								<BtnReturn onClick={logOut}>Take me to Smoothi!</BtnReturn>
							</BdayContainer>
						</SmoothiContent>
					</SmoothiContainer>
				)
			}
			else if (data.register.errors.email){
				return(
					<AccountNotCreated logOut={logOut} message={data.register.errors.email[0].message}/>
				)		
			}
			else if (data.register.errors.username) {
				return(
					<AccountNotCreated logOut={logOut} message={data.register.errors.username[0].message}/>
				)	
			}
			else if (data.register.errors.nonFieldErrors){
				return(
					<AccountNotCreated logOut={logOut} message={data.register.errors.nonFieldErrors[0].message}/>
				)	
			}
			else if (data.register.errors.password2){
				return(
					<AccountNotCreated logOut={logOut} message={data.register.errors.password2[0].message}/>
				)	
			}
			else {
				<SmoothiContainer>
					<ImageBg/>
					<SmoothiContent>
						<CreateAccountHeader>
							Account Creation Failed
						</CreateAccountHeader>
						<BdayContainer>
							<BtnReturn onClick={logOut}>Back to Main Page</BtnReturn>
						</BdayContainer>
					</SmoothiContent>		
				</SmoothiContainer>
			}
		}
		else {
			<SmoothiContainer>
				<ImageBg/>
				<SmoothiContent>
					<CreateAccountHeader>
						Account Creation Failed
					</CreateAccountHeader>
					<BdayContainer>
						<BtnReturn onClick={logOut}>Back to Main Page</BtnReturn>
					</BdayContainer>
				</SmoothiContent>		
			</SmoothiContainer>
		}
	}
	else {
		<SmoothiContainer>
			<ImageBg/>
				<SmoothiContent>
					<BdayContainer>
						<BtnReturn onClick={logOut}>Back to Main Page</BtnReturn>
					</BdayContainer>
				</SmoothiContent>						
		</SmoothiContainer>
	}
}

function TermsSection({ logOut, goToCreated }) {
	return (
		<div>
			<ImageBg/>
			<TermsContent>
				<iframe 
					title="Terms and Conditions" 
					src="https://app.termly.io/policy-viewer/policy.html?policyUUID=f0e4597f-ed28-4832-b5bf-177575a226ae"
					style={{background: "#EFEBE5", borderColor:"black", height:"280px", width:"100%"}}/>
				<br/>
				<AcceptContainer>
					<BtnTerms onClick={goToCreated}>I accept</BtnTerms>
					<BtnTerms2 style={{cursor: 'pointer'}}> 
						<p style={{ textAlign: 'center' }} onClick={logOut}>I don't accept </p>
					</BtnTerms2>
				</AcceptContainer>
			</TermsContent>
		</div>
	)
}

function CreateAccountPage({ logOut }) {
	const [page, setPage] = useState('createAccount')
	const [userData, setUserData] = useState({
		'email': '',
		'username': '',
		'password': '',
		'birthday': ''
	})

	//const [createAccountFunction, { data, loading, error }] = useMutation(CREATE_ACCOUNT, { errorPolicy: 'all' })
	//const [networkError, setNetworkError] = useState(false)
//
	//const createAccount = async () => {
	//	try {
	//		await createAccountFunction({ variables: { username: userData.username, email: userData.email, password: userData.password, birthday: userData.birthday } })
	//	}
	//	catch (createError) {
	//		setNetworkError(true)
	//	}
	//}

	switch (page) {
		case 'createAccount':
			return (
				<CreateAccount 
					goToCreated={() => { setPage('terms') }} 
					setUserData={setUserData}
					logOut={logOut}/>
			)
		case 'terms':
			return(
				<SmoothiContainer>
					<ImageBg />
					<ExploreContent>
					<TermsSection 
						logOut={logOut} 
						goToCreated={() => { setPage('accountCreated') }} />
						</ExploreContent>
				</SmoothiContainer>
			)
		case 'accountCreated':
			return (
				<SmoothiContainer>
					<ImageBg/>
					<ExploreContent>
					<AccountCreated logOut={logOut} userData={userData}/>
					</ExploreContent>
				</SmoothiContainer>
			)
		default:
			return(
				<SmoothiContainer>
					<ImageBg />
					<ExploreContent>
				
					</ExploreContent>
				</SmoothiContainer>
			)
	}
}

export default CreateAccountPage