import { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client';
import LogInSection from '../components/LogInSection';
import { ImageBg, SmoothiContent } from '../components/AppComponents/appElements';

const GET_TOKEN = gql`
  mutation ($username: String!, $password: String!){
    tokenAuth(username: $username, password: $password){
      token
			errors
			success
    }
  }
`

function LogInPage({logIn, setCreateAccountPage, setForgotPage,}) {

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	//const [credentialsError, setCredentialsError] = useState(false)
	const [message, setMessage] = useState('')
	const [serverError, setServerError] = useState(false)

	const [getTokenFunction, { data, loading, error }] = useMutation(GET_TOKEN, { errorPolicy: 'all' })

	const handleSignIn = () => {
		if (username && password) {
			try {
				getTokenFunction({ variables: { username: username, password: password } })
			}
			catch (error) {
				setServerError(true)
			}
		}

	}

	const handleUsernameChange = (event) => {
		setUsername(event.target.value)
	}

	const handlePasswordChange = (event) => {
		setPassword(event.target.value)
	}

	//useEffect(()=>{
	//	document.addEventListener("keypress", (e)=>{
	//		if (e.key === "Enter"){
	//			handleSignIn()
	//		}
	//	});
	//	return () => {
	//		// Cleanup the event listener
	//		document.removeEventListener("mousedown", (e) => { 
	//			if (e.key === "Enter") {
	//				handleSignIn()
	//			}
	//		});
	//	};
	//})

	useEffect(() => {
		if (error) {
			setServerError(true)
		}
		else if (data) {
			if (data.tokenAuth.success) {
				const token = data.tokenAuth.token

				localStorage.setItem('LogInToken', token)
				//setCredentialsError(false)
				setServerError(false)
				logIn(token)
			}
			else if (!data.tokenAuth.success) {
				//setCredentialsError(true)
				setMessage(data.tokenAuth.errors.nonFieldErrors[0].message)
			}
		}
	}, [getTokenFunction, data, loading, error, logIn])

	return(
		<div>
			<SmoothiContent>
				<ImageBg />
				<LogInSection
					username={username}
					password={password}
					handleUsernameChange={handleUsernameChange}
					handlePasswordChange={handlePasswordChange}
					handleSignIn={handleSignIn}
					setCreateAccountPage={setCreateAccountPage} 
					setForgotPage={setForgotPage}
					message={message}/>
				
				<p style={{ color: "black" }}>{serverError}</p>
				</SmoothiContent>
		</div>
	)
}

export default LogInPage