import React from 'react'
import {HomeTextWrap,CreateTextWrap,SettingsTextWrap,FooterWrap, FooterContainer,HomeIcon,CreateIcon,IconWrap,SettingsIcon} from './FooterElements'

function Footer ({currentPage, goToMainMenu, goToChoices, goToSettings}) {
  return (
    <FooterContainer>
      <FooterWrap>
        <IconWrap> 
          <HomeIcon current={currentPage} onClick={goToMainMenu}/> 
          <HomeTextWrap current={currentPage} > Home</HomeTextWrap>
        </IconWrap>
        <IconWrap>
          <CreateIcon current={currentPage} onClick={goToChoices}/>
          <CreateTextWrap current={currentPage} >  Create</CreateTextWrap>
        </IconWrap>
        <IconWrap>
          <SettingsIcon current={currentPage} onClick={goToSettings}/>
          <SettingsTextWrap current={currentPage}>  Settings</SettingsTextWrap>
        </IconWrap>
      </FooterWrap>  
    </FooterContainer>
  )
}

export default Footer
