import { PageTitleH2 } from "../AppComponents/appElements";
import { DummyFooter, MenuContainer, ExploreContainerGroup, ExploreAllButtonContainer, ExploreButtonContainer, ExploreButtonBackground, ExploreButton, MenuT1, PlayIcon, PlusIcon, CreateButton } from "./menuElements"
import LoadingPage from "../../pages/loadingPage";
import ErrorPageInApp from "../../pages/errorPageInApp";
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from "react";

const GET_HOT_AND_FAV_TITLES = gql`
	query GetTitles {
		hotTitles {
			mixId
			picture
      songPreviewPlaylist{
        songTitle
        artist
      }
			choices {
			  choicesId
        title
			}
		}
		favoriteTitles {
			mixId
			picture
      songPreviewPlaylist{
        songTitle
        artist
      }
			choices {
			  choicesId
        title
			}
		}
	}
	`

function ExploreButtonComponent (props) {
	return (
		<ExploreButtonContainer>
			<ExploreButtonBackground>
				<ExploreButton onClick={props.clickSlice} style={{cursor: 'pointer'}} background={props.background} name={'RnB'} ><PlayIcon /></ExploreButton>
			</ExploreButtonBackground>
			<MenuT1> {props.name} </MenuT1>
		</ExploreButtonContainer>
	)
}

function CreateMixComponent(props) {
	const create_button = ''
	return (
		<ExploreButtonContainer>
			<CreateButton onClick={props.goToChoices} style={{cursor: 'pointer'}} background={create_button} name={'createMix'} ></CreateButton>
			<PlusIcon onClick={props.goToChoices} style={{cursor: 'pointer'}} />
			<MenuT1> Create Your Mix </MenuT1>
		</ExploreButtonContainer>
	)
}

function SliceButtons ({hotObjects, clickSlice, goToChoices}) {
	const buttons = hotObjects.map((e, index) => {
		return <ExploreButtonComponent
		clickSlice={() => {
			clickSlice(e.choices.choicesId, e.choices.title,e.picture,e.songPreviewPlaylist,e.mixId)
		}}
		background={e.picture}
		name={e.choices.title}
		key={index} />
	})
	return (
		<ExploreAllButtonContainer>
			{buttons}
			<CreateMixComponent goToChoices={goToChoices} />
		</ExploreAllButtonContainer>
	)
}

function HotCrateComponent({clickSlice, goToChoices, hotObjects}) {
	return (
		<ExploreContainerGroup>
			<PageTitleH2>Hot Crate</PageTitleH2>
			<SliceButtons
				clickSlice={clickSlice}
				goToChoices={goToChoices} 
				hotObjects={hotObjects}/>
		</ExploreContainerGroup>
	)
}

function FavoritesComponent({clickSlice, goToChoices, favoriteObjects}) {
	return (
		<ExploreContainerGroup>
			<PageTitleH2>My Picks</PageTitleH2>
			<SliceButtons
				clickSlice={clickSlice}
				goToChoices={goToChoices} 
				hotObjects={favoriteObjects}/>
		</ExploreContainerGroup>
	)
}


function MenuSection({ goToChoices, goToPreview2, setCurrentSlice }) {
	const { loading, error, data } = useQuery(GET_HOT_AND_FAV_TITLES);
	const [hotTitles, setHotTitles] = useState([])
	const [favoriteTitles, setFavoriteTitles] = useState([])
	
	const clickSlice = (slice_id, slice_title, picture, songPreviewPlaylist, mixId) => {
		// must change to single state change because of double render of preview
		setCurrentSlice({ 'slice_id': slice_id, 'slice_title': slice_title,'picture':picture,'songPreviewPlaylist':songPreviewPlaylist,'mixId':mixId })
		goToPreview2()
	}


	useEffect(() => {
		if (data ) {
			const titles_stored = []
			const new_titles = data.hotTitles.map((e) => {
				titles_stored.push(e.choices.title)
				return { "title": e.choices.title, "choicesId": e.choices.choicesId }
			})
			setHotTitles(new_titles)
		}
	}, [data])

	useEffect(() => {
		if (data ) {
			const titles_stored = []
			const new_favorite_titles = data.favoriteTitles.map((e) => {
				titles_stored.push(e.choices.title)
				return { "title": e.choices.title, "choicesId": e.choices.choicesId }
			})
			setFavoriteTitles(new_favorite_titles)
		}
	}, [data])

	if (loading) {
		return (
			<LoadingPage/>
		)
	}
	else if (error) {
		
		return(
			<ErrorPageInApp/>
		)
	}
	else if (data) {
		return (
			<MenuContainer>
				
				<HotCrateComponent
				
					clickSlice={clickSlice}
					goToChoices={goToChoices}
					hotObjects={data.hotTitles}
					titles={hotTitles}/>
				<FavoritesComponent
					clickSlice={clickSlice}
					goToChoices={goToChoices}
					favoriteObjects={data.favoriteTitles}
					titles={favoriteTitles}/>
				<DummyFooter></DummyFooter>
				
			</MenuContainer>
		)
	}
}

export default MenuSection