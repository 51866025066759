import styled from 'styled-components'
//import {Link as LinkR} from 'react-router-dom'
//import {Link as LinkS} from 'react-scroll'

export const Container = styled.SafeAreaView;

export const Nav = styled.nav`
  background: rgba(0, 0, 0, 0);
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	top: 0;
	z-index: 10;
	
	@media screen and (max-width: 960px) {
		transition: 0.8s all ease;
	}
`

export const StyledSlot = styled.div`
  flex: 1;
  align-content: center;
  justify-content: center;
  width: 94%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 0;
  margin-top: 80px;
  opacity:0.15;
`;

export const NavbarContainer = styled.div`
	display: flex;
	
	justify-content: space-between;
	height: 80px;
	z-index: 1;
	width: 100%;
	max-width: 1300px;
`

export const LogoBg = styled.img`

    width: 300px;

	margin-left: -50px;
	-o-object-fit: cover;
	object-fit: cover;
	margin-top:5px;

	@media screen and (max-width: 400px) {
		margin-left: -35px;
	}

	@media screen and (max-width: 300px) {
		margin-left: -20px;
	}
`

export const MobileIcon = styled.div`
	display: ${({ isLanding }) => (isLanding ? 'block' : 'none')};
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-100%, 45%);
	color: #fff;
	font-size: 1.8rem;
	cursor: pointer;
	
	///@media screen and (max-width: 768px) {
		///display: block;
		///position: absolute;
		///top: 0;
		///right: 0;
		///transform: translate(-100%,40%);
		///font-size: 1.8rem;
		///cursor: pointer;
		///color: #fff;
	///}
`

export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	margin-right: -22px;
	@media screen and (max-width: 769px){
		display: none;
	}
`

export const NavItem = styled.li`
	height: 80px;
`

//export const NavLinks = styled(LinkS) `
export const NavLinks = styled.li `
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;
	&.active {
		border-bottom: 3px solid #01bf71;
	}
`

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	@media screen and (max-width: 768) {
		display: none;
	}
`
//export const NavBtnLink = styled(LinkR)`
export const NavBtnLink = styled.li`
	border-radius: 50px;
	background: #01bf71;
	white-space: nowrap;
	padding: 10px 22px;
	color: #010606;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	&.hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #010606;
	}
`
export const BtnNavOptContainer = styled.div`

margin-right:20px;
z-index: 2;
color: white;

font-family: 'Oxygen', sans-serif;
font-size: 12.5px;
letter-spacing: 1px;
font-Weight: bold;

&:hover{
	opacity:1;
	color:#ECD4CA;
	transition:0.3s ease-out;
}

@media screen and (max-width: 350px) {
	font-size: 11px;
	margin-right:15px;
}

@media screen and (max-width: 300px) {
	font-size: 10px;
	margin-right:15px;
}
`

export const NavOptContainer = styled.div`
display: flex;
align-items: center;
width: 200px;
justify-content: flex-end;
padding-bottom:1.5rem;
margin-top: 43px;
margin-right:10px;
z-index: 2;
color: white;
font-family: 'Oxygen', sans-serif;
font-size: 12.5px;
font-Weight: bold;

&:hover{
	opacity:1;
	color:#ECD4CA;
	transition:0.3s ease-out;
}

@media screen and (max-width: 350px) {
	margin-right:0px;
}

@media screen and (max-width: 300px) {
	margin-right:-15px;
}
`