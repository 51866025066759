import  {OrangeError}  from "../components/AppComponents/appElements";

function ErrorPageInApp() {
  return(
    <div>
      <OrangeError/>
      <p style={{color:"#D34F1D", fontFamily: 'Inter'}}>Error :/ </p>
    </div>
  )
}

export default ErrorPageInApp