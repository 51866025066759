import { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
//import { Browser } from '@capacitor/browser'
import { PlansWrapper, AccountContent, RadioButtonContainer, RadioButton,CostText, ChangePlanBox,ChangePlanSubbox,ChangePlanButton, ChangePlanText } from '../components/AppComponents/appElements';
import { PreviewTitle, PreviewMenu, PreviewHeader, PreviewHeaderLeft,PreviewMixListItem, PreviewSongTitle, PreviewSongArtist,StyledSlot3,PreviewMixListContainer} from '../components/PreviewSection/PreviewElements';
import CancelPage from './cancelPage';
import LoadingPage from './loadingPage';
import ErrorPage from './errorPage';

const SUBSCRIBE_TO_PREMIUM = gql`
  mutation SubscribeToPremium {
    createStripeCheckoutSessionPremium{
      urlSession
    }
  }
`;

function Plan({isSelected, planType, text, cost, clickedPlan}) {
	return (
		<PreviewMixListItem>
			<ChangePlanBox>
				<RadioButtonContainer>
					<RadioButton isSelected={isSelected} onClick={() => { clickedPlan(planType) }} />
					<ChangePlanSubbox>
						<PreviewSongTitle style={planType === 'Premium'?{ color:'#D34F1D' }:{}}>
						 {planType}
						</PreviewSongTitle>
						<PreviewSongArtist>
							{text}
						</PreviewSongArtist>
					</ChangePlanSubbox>
				</RadioButtonContainer>
				<CostText style={planType === 'Freemium'?{ color:'black' }:{}}>
					{cost}
				</CostText>
			</ChangePlanBox>
		</PreviewMixListItem>
	)
}

function PlansMenu({planSelections, setPlanSelections, clickedChangeYourPlan, accountType, subscriptionEnding}) {

	const [isChange, setIsChange] = useState(false)

  const clickedPlan = (choice) => {
    if (choice === "Freemium"){
      setPlanSelections(planSelections => ({...planSelections, 
				Freemium: true, 
				Premium: false,
				Cancel: false}))
    }
    else if (choice === "Premium"){
      setPlanSelections(planSelections => ({...planSelections, 
				Freemium: false, 
				Premium: true,
				Cancel: false}))
    }
		else if (choice === "Cancel"){
      setPlanSelections(planSelections => ({...planSelections, 
				Freemium: false, 
				Premium: false,
				Cancel: true}))
    }
  }

  useEffect(()=>{
    if (planSelections.Premium && accountType === 'PREMIUM'){
        setIsChange(true)
      }
		else if (planSelections.Premium && accountType === 'FREE'){
			setIsChange(true)
		}
  }, [planSelections, accountType])
  
	const subscribedBool = (accountType === 'PREMIUM' )

	return(
		<PlansWrapper>
			<PreviewMenu>
				<PreviewHeader>
    		  <PreviewHeaderLeft>
	  				<PreviewTitle>Plans</PreviewTitle>
    			</PreviewHeaderLeft>
				</PreviewHeader>
				
				<PreviewMixListContainer>
					{subscribedBool?<></>:<Plan isSelected={planSelections.Freemium}
						planType={'Freemium'} 
						text={'200 minutes per week'} 
						cost={'Free'} 
						clickedPlan={clickedPlan}/>}
					<StyledSlot3 />
					<Plan isSelected={planSelections.Premium} 
						planType={'Premium'} 
						text={'Unlimited mixes, no ads'} 
						cost={subscriptionEnding? 'Reactivate your subscription.':''} 
						clickedPlan={clickedPlan}/>
					

					{(subscribedBool && !subscriptionEnding) ? <Plan isSelected={planSelections.Cancel} 
						planType={'Cancel'} 
						text={'Cancel your plan'} 
						cost={'We\'re sad to see you go!'} 
						clickedPlan={clickedPlan}/>:<div/>}

				</PreviewMixListContainer>
			</PreviewMenu>
			<br/>
			<ChangePlanButton isChange={isChange} onClick={clickedChangeYourPlan}>
				<ChangePlanText>
				 Change Your Plan
				</ChangePlanText>
			</ChangePlanButton>
		</PlansWrapper>
	)
}

function OpenedStripeURL({backToSettingsMenu}) {
	useEffect(()=>{
		backToSettingsMenu()
	})
	return(
		<div/>
	)
}

function PlansPage({backToSettingsMenu, subscriptionEnding, accountType, setClickedChangePlan}){
	const [planSelections, setPlanSelections] = useState({Freemium: true, Premium: false, Cancel: false})
	const [cancelPage, setCancelPage] = useState(false)

	const [subscribeToPremium, { data, loading, error }] = useMutation(SUBSCRIBE_TO_PREMIUM, { errorPolicy: 'all' })

	useEffect(()=> {

		if (accountType === 'FREE') {
			setPlanSelections({Freemium: true, Premium: false, Cancel: false})
		}
		else if (accountType === 'PREMIUM') {
			setPlanSelections({Freemium: false, Premium: true, Cancel: false})
		}

	}, [accountType])

	//Browser.addListener('browserFinished', () => {
	//	refetch()
	//});

	const clickedChangeYourPlan = () => {
		if ((planSelections.Premium && accountType!== 'PREMIUM') || subscriptionEnding) {
			setClickedChangePlan(true)
			subscribeToPremium()
		}
		if (planSelections.Cancel) {
			setCancelPage(true)
		}
	}

	if (data) {
		const premium_link = data.createStripeCheckoutSessionPremium.urlSession
		const openPremiumSite = () => {
			// Browser.open({ url: premium_link });
			window.location.href = premium_link
		};
		if (premium_link !== ''){
			//window.location.href = premium_link
			openPremiumSite()
		}
		return(
			<OpenedStripeURL backToSettingsMenu={backToSettingsMenu}/>
		)
	}

	if (loading) {
		return(
			<LoadingPage/>
		)
	}

	if (cancelPage){
		return (
			<CancelPage setClickedChangePlan={setClickedChangePlan}/>
		)
	}
	if (error) {
		return(
			<ErrorPage/>
		)
	}

	return (
		<AccountContent>
			<PlansMenu backToSettingsMenu={backToSettingsMenu} 
				planSelections={planSelections} 
				setPlanSelections={setPlanSelections} 
				clickedChangeYourPlan={clickedChangeYourPlan}
				accountType={accountType}
				subscriptionEnding={subscriptionEnding}/>
		</AccountContent>
	)
}

export default PlansPage