import styled from 'styled-components'

export const SmoothiLogo = styled.img`
	
	flex: 1;
	//margin-left: 15px;
	//margin-top:2px;
  	margin-bottom:-130px;
	margin-top:-70px;
	margin-left:-85px;
	

    width:420px;   
    transform: scale(.5);
	
	

	@media screen and (max-width: 500px) {
		margin-left: -95px;
	}
	
	
  `