import styled from 'styled-components'
import { GiPauseButton } from 'react-icons/gi'
import { FaPlay } from "react-icons/fa"


export const PlayerTitle = styled.div`
	textAlign: center;
	color: #393939;
	font-size: 24px;
	padding-bottom:0.5rem;
	font-family: 'Inter';
	font-weight: bold;

	@media screen and (max-height: 700px) {
		font-size: 22px;
		padding-bottom:0.3rem;
`

export const PlayerSubTitle = styled.div`
textAlign: center;
	color: #393939;
	font-size: 14px;
	padding:0.1rem;
	font-family: 'Inter';
`

export const PlayerTimer = styled.div`
	textAlign: center;
	color: #393939;
	font-size: 14px;
	padding:0.2rem;
	@media screen and (max-height: 700px) {
		
		padding-bottom:0.0rem;
`

export const PlayerSong = styled.div`
	textAlign: center;
	color: #393939;
	font-size: 24px;
	
	font-family: 'Inter';
	font-weight: bold;
	margin-top:22px;

	@media screen and (max-height: 700px) {
		
		margin-top:10px;
	
`

export const PlayerArtist = styled.div`
	textAlign: center;
	color: #393939;
	font-size: 15px;
	padding:0.1rem;
	font-family: 'Inter';
	font-weight: 600;

	@media screen and (max-height: 700px) {
		
		padding:0.0rem;
`


export const PlayerBg = styled.div`
	position: absolute;
	overflow: hidden;
	//why this offset?
	padding-bottom:1.5px;
	
	
`

export const PlayerImgZoom = styled.img`
	
	border-radius: 50%;
	
	
`

export const PlayerImgBgSW = styled.img`

	width:134px;
	height:134px;
	border-top-left-radius:100%;

	@media screen and (max-width: 325px) {
		
		width:110px;
		height:110px;

	
`

export const PlayerImgBgSE = styled.img`
	width:134px;
	height:134px;
	border-top-right-radius:100%;

	@media screen and (max-width: 325px) {
		
		width:110px;
		height:110px;

	
`

export const PlayerImgBgNE = styled.img`
	width:134px;
	height:134px;
	border-bottom-left-radius:100%;
	position: relative;
	bottom:3px;

	@media screen and (max-width: 325px) {
		
		width:110px;
		height:110px;
	
	
`

export const PlayerImgBgNW = styled.img`
	width:134px;
	height:134px;
	border-bottom-right-radius:100%;
	position: relative;
	right: 0;
	bottom:3px;

	@media screen and (max-width: 325px) {
		
		width:110px;
		height:110px;
	
	
	
`

export const PlayContainer = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
    padding-bottom:1.5rem;
	position: absolute;
	z-index: 2;

	

	
`

export const PauseBtn = styled(GiPauseButton)`
	color: white;
	font-size:1.75rem;
`

export const PlayBtn = styled(FaPlay)`
	color: white;
	font-size:1.75rem;
	padding-left:0.4rem;
`

export const PlayerContent = styled.div`
	width:300px;
	z-index: 2;
	text-align: center;
  align-items: center;

  @media screen and (max-width: 325px) {
	width:250px;
	
	
	
`

export const PlayerHeader = styled.div`
	
margin-bottom: -15px;	

@media screen and (max-height: 700px) {
	margin-bottom: -25px;
`

export const BtnBackToMenu = styled.button`
  color: ${({ isSelected }) => (isSelected ? 'rgb(0, 0, 0, 1)' : 'rgb(256, 256, 256, 1)')};
  border: 2px solid white;
  border-radius: 30px;
  font-size: 12px;
  background: ${({ isSelected }) => (isSelected ? 'rgb(256, 256, 256, 1)' : 'rgb(0, 0, 0, 0)')};
  padding: 8px 20px;
  margin: 2em 2em;
  width: 120px;
  top:0px;
  

  &:hover{
	
	color:#ECD4CA;
	transition:0.3s ease-out;
}

@media screen and (max-width:768px) {
	font-size: 10px;
	
	width: 110px;
	padding: 8px 12px;
	
  }

  @media screen and (max-width: 480px) {
	font-size: 9px;
	
	width: 100px;
	padding: 8px 12px;

`

export const BtnBackToMenuContainer = styled.div`
	display: flex;
	width: 100%;
   	justify-content: flex-end;
    padding-bottom:1.5rem;
    margin-right:30px;
	position: absolute;
	z-index: 10;
	top:100px;

	
	
`


