import styled from 'styled-components'
import { AiFillPlusCircle, AiFillPlayCircle } from 'react-icons/ai'
import { FaTimes } from "react-icons/fa";


export const MenuContainer = styled.div`
	justify-content: center;
	margin-bottom:20px;
	color:black;
	
	

`

export const ExploreContainer = styled.div`
	text-align: center;
	justify-content: center;
	z-index:7;
	
	
`

export const ExploreContainerGroup = styled.div`
	justify-content: center;
	z-index:9;
	
`

export const ExploreAllButtonContainer = styled.div`
	text-align: left;
	display: flex;
	flex-direction: row;
	width:640px;
	flex-wrap: wrap;
	z-index:11;
	justify-content: center;
	

	@media screen and (max-width: 700px) {
		width:320px;
	}

	@media screen and (max-width: 300px) {
		width:250px;
	}
`

export const ExploreButtonContainer = styled.div`
	margin: .7em .5em;
	justify-content: center;
	align-items: top;
	text-align: center;
	width: 90px;
	height: 130px;

	@media screen and (max-width: 420px) {
		margin: .2em .5em;
	}

	@media screen and (max-width: 400px) {
		margin: -.25em .5em;
	}

	@media screen and (max-width: 440px) and (min-height: 900px) {
		margin: .35em .5em;
	}
` 

export const ExploreButtonBackground = styled.div`
	width: 90px;
	height: 90px;
	background:rgb(1, 1, 1);
	border-radius: 20px;
`

export const ExploreButton = styled.button`
  color: ${({ isSelected }) => (isSelected ? 'rgb(0, 0, 0, 1)' : 'rgb(256, 256, 256, 1)')};
  border: 0px solid white;
  border-radius: 20px;
  font-size: 12px;
  padding: 8px 20px;

	width: 90px;
	height: 90px;
	opacity:0.8;
	background-image: url(${({ background }) => background});	
  	background-repeat: no-repeat;
  	background-size: cover;
	&:hover{
		opacity:1;
		//color:#ECD4CA;
		transition:0.3s ease-out;
	}
`;

export const MenuT1 = styled.h1`
	color: black;
	font-size: 10.5px;
	margin: .4em 0em;
	font-weight:600;
`

export const PlayIcon = styled(AiFillPlayCircle)`
	margin-left:-38px;
	margin-top:22px;
	margin-right:00px;
	font-size:17.5px;
	z-index: 3;
	position:absolute;
`

export const PlusIcon = styled(AiFillPlusCircle)`
	margin-left:-60px;
	margin-top:30px;
	margin-right:0px;
	font-size:32px;
	z-index: 0;
	position:absolute;
	color: #353535;
`

export const CreateButton = styled.button`
  color: ${({ isSelected }) => (isSelected ? "'rgb(0, 0, 0, 1)'" : "'rgb(250, 256, 256, 1)'")};
  border: 0px solid white;
  border-radius: 20px;
  font-size: 12px;
  padding: 8px 20px;
	width: 90px;
	height: 90px;
	
	background-image: url(${({ background }) => background});	
  	background-repeat: no-repeat;
  	background-size: cover;
  	z-index: 1;
	&:hover{
		opacity:.65;
		transition:0.3s ease-out;
	}
	border-radius: 25px;
	opacity: 0.8999999761581421;
	background: #F6F5F5;
	box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.20) inset;
`;

export const StyledSlot2 = styled.div` 
	flex: 1;
	align-content: center;
	justify-content: center;
	width: 100%;
	height: 1px;
	background-color: #fff;
	top: 0;
	z-index: 11;
	opacity:0.15;
	margin-bottom:20px;
	margin-top:20px;
	margin-left:0px;
	@media screen and (max-width: 700px) {
		width:320px;
	}
`

export const PreviewContainer = styled.aside`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.01s ease-in-out;
  top: ${({ isOpen }) => (isOpen ? ' 0%' : '-100%')};
  justify-content: center;
  
  
`

export const CloseIcon = styled(FaTimes)`
	color: #fff;
	font-size:20px;
`

export const PreviewWrapper = styled.div`
	width: 700px;
  	height: 650px;
	border-radius: 30px;

	@media screen and (max-width: 800px) {
		width: 600px;
	}

	@media screen and (max-width: 600px) {
		width: 450px;
	}

	@media screen and (max-width: 500px) {
		width: 350px;
	}

	@media screen and (max-width: 450px) {
		width: 300px;
	}

`
export const PreviewBgd = styled.div`
	background:#000000;	
	opacity:78%;
	width: 700px;
  	height: 620px;
	border-radius: 30px;
	z-index: 11;
	position:absolute;
	

	@media screen and (max-width: 800px) {
		width: 600px;
	}

	@media screen and (max-width: 600px) {
		width: 450px;
	}

	@media screen and (max-width: 500px) {
		width: 350px;
	}

	@media screen and (max-width: 450px) {
		width: 300px;
	}

`

export const PreviewMenu = styled.div`

`

export const PreviewContent= styled.div`
	position:absolute;
	color:#FFFFFF;
	width:600px;
	margin-top:32px;
	margin-left:32px;
	z-index: 12;

	@media screen and (max-width: 800px) {
		width: 500px;
	}
	@media screen and (max-width: 600px) {
		width: 400px;
	}
	@media screen and (max-width: 500px) {
		width: 300px;
	}
	@media screen and (max-width: 450px) {
		width: 280px;
		margin-left:10px;
	}
`


export const PreviewMixListHeader = styled.div`
	margin-top:0px;
	display:flex;
	flex-direction:row; 
	justify-content:space-between;
	font:Inter;
	font-size: 11px;
	color: white;	
	margin-left:12px;
	margin-right:12px;
	margin-bottom:5px;
	text-align: center;
	align-items: center;

	@media screen and (max-width: 450px) {
		font-size:8px;
		margin-left:2px;
		margin-right:15px;
	}
`;

export const StyledSlot3 = styled.div`
	flex: 1;
	align-content: center;
	justify-content: center;
	width: 100%;
	height: 0.75px;
	background-color: #9C9797;
	top: 0;
	z-index: 11;
	opacity:0.45;
	margin-bottom:3px;
	margin-top: 3px ;
`;

export const DummyFooter = styled.div`

height: 100px;

@media screen and (max-height: 810px) {
		height: 140px;
	}

@media screen and (max-height: 750px) {
		height: 160px;
	}

`;

