import { useState } from "react"
import { gql, useMutation } from '@apollo/client';
import { ResetPassButton, Input, CreateAccountHeader, PButton } from "../components/LogInSection/logInElements"
import { ChangePasswordWrapper, PasswordChangedWrapper } from "../components/AppComponents/appElements"
import LoadingPage from "./loadingPage";
import ErrorPage from "./errorPage";

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordChange(oldPassword: $oldPassword, newPassword1: $newPassword1, newPassword2: $newPassword2){
      success
      errors
      token
    }
  }`
;

function ChangePasswordPage() {
  const [oldPassword, setOldPassword]  = useState('')
  const [newPassword1, setNewPassword1] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [buttonClicked, setButtonClicked] = useState(false)

  const [password1Valid, setPassword1Valid] = useState(false)

  const [changePasswordFunction, { data, loading, error }] = useMutation(CHANGE_PASSWORD, { errorPolicy: 'all' })

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value)
  }

  const handleNewPassword1Change = (event) => {
    setNewPassword1(event.target.value)
    if (event.target.value.length > 7) {
      setPassword1Valid(true)
    }
    else{
      setPassword1Valid(false)
    }
  }

  const handleNewPassword2Change = (event) => {
    setNewPassword2(event.target.value)
  }

  const handleChangePassword = () => {
    setButtonClicked(true)
    if (oldPassword && password1Valid && (oldPassword !== newPassword1) && (newPassword1 === newPassword2)){
      changePasswordFunction({ variables: { oldPassword: oldPassword, newPassword1: newPassword1, newPassword2: newPassword2} })
    }
  }

  if (loading) {
    return(
      <LoadingPage/>
    )
  }

  if (data ) {
    if (data.passwordChange.success) {
      localStorage.setItem('LogInToken', data.passwordChange.token)
      return (
        <PasswordChangedWrapper>
          Password succesfully changed!
        </PasswordChangedWrapper>
      )
    }
    else if (data.passwordChange.errors.oldPassword[0].code === 'invalid_password'){
      return (
        <PasswordChangedWrapper>
          Invalid password
        </PasswordChangedWrapper>
      )
    }
    
  }
 
  if (error) {
    return(
      <ErrorPage/>
    )
  }

  return(
    <ChangePasswordWrapper>
      <CreateAccountHeader >
   			  Change Password
	 			</CreateAccountHeader>

      <Input style={{margin:"0.3px"}}
						type="password" 
						placeholder={'Current Password'} 
						value={oldPassword} 
						onChange={handleOldPasswordChange} />
      <PButton>   

      </PButton> 
      
      <Input style={{margin:"0.3px"}}
						type="password" 
						placeholder={'New Password'} 
						value={newPassword1} 
						onChange={handleNewPassword1Change} />
      <PButton>   
      {newPassword1 && buttonClicked && !password1Valid ? 'Password must be a least 8 characters long.':''}
      {newPassword1 && buttonClicked && password1Valid && (oldPassword === newPassword1)? 'New and old passwords cannot match': ''}
      </PButton> 
      <Input style={{margin:"0.3px"}}
						type="password" 
						placeholder={'Confirm New Password'} 
						value={newPassword2} 
						onChange={handleNewPassword2Change} />
      <PButton> 
      {(newPassword2 !== newPassword1) ? 'Passwords must match':""}
      </PButton> 

      
      <ResetPassButton onClick={handleChangePassword} style={{cursor: 'pointer'}}>Done </ResetPassButton>
    
    </ChangePasswordWrapper>
  )
}

export default ChangePasswordPage