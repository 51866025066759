import { useEffect, useState } from "react"
import { Button, EnergySliderContainer,AdvancedContainer, SelectionContainer,AccordianDropdownTextWrap,ButtonSmall } from "./choicesElements"
import { StyledSlot3 ,ChoiceHeadingContainer,NextPageButton} from "./choicesElements"
import { FaAngleLeft} from 'react-icons/fa'
import CircularSlider from '@fseehawer/react-circular-slider';
import {PreviousButtonContainer} from "../AppComponents/appElements";

function ChooseGenre(props) {
  const [genreSelections, setGenreSelections] = useState({Hiphop:false, Pop:false, Rnb:false})
  const [isValid, setIsValid] = useState(false)

  const clickedChoice = (choice) => {
    if (choice === "Pop"){
      setGenreSelections(genreSelections => ({...genreSelections, Pop: !genreSelections.Pop}))
    }
    if (choice === "RnB"){
      setGenreSelections(genreSelections => ({...genreSelections, Rnb: !genreSelections.Rnb}))
    }
    if (choice === "Hip-hop"){
      setGenreSelections(genreSelections => ({...genreSelections, Hiphop: !genreSelections.Hiphop}))
    }
  }

  const goToNextSection = () => {
    props.setGenre(genreSelections)
    props.goToNextSection()
  }

  useEffect(()=>{
    setIsValid(false)
    for (const entry of Object.entries(genreSelections)) {
      if (entry[1]) {
        setIsValid(true)
      }
    }
  },[genreSelections])

  return(
    <div>
      <ChoiceHeadingContainer>Music Genre(s)</ChoiceHeadingContainer>
      <SelectionContainer>
        <Button name={'Pop'} 
                isSelected={genreSelections.Pop}
                onClick={() => { clickedChoice('Pop') }}>Pop</Button>
        <Button name={'RnB'} 
                isSelected={genreSelections.Rnb}
                onClick={()=>{clickedChoice('RnB')}}>R&B</Button>
        <Button name={'Hip-hop'} 
                isSelected={genreSelections.Hiphop}
                onClick={() => { clickedChoice('Hip-hop') }}>Hip-hop</Button>
      
      <NextPageButton isValid={isValid} onClick={() => {if (isValid) {goToNextSection()}}}>
        Next
      </NextPageButton>
      </SelectionContainer>
    </div>
    
  )
}

function ChooseExtras({setNextSection, setPreviousSection, genre, setExtras}) {
  const [extrasSelections, setExtrasSelections] = useState([])
  //const [isValid, setIsValid] = useState(false)
  const [isEDMValid, setEDMIsValid] = useState(true)

  const handleRemoveItem = (choice) => {
    setExtrasSelections(extrasSelections.filter(item => item !== choice));
  };

  const clickedChoice = (choice) => {
    if (!extrasSelections.includes(choice)) {
      setExtrasSelections([...extrasSelections, choice])
    }
    else {
      handleRemoveItem(choice)
    }
  }

  const goToNextSection = () => {
    // extras selections must be sorted alphabetically
    setExtras(extrasSelections.sort())
    setNextSection()
  }

  const goToPreviousSection = () => {
    setExtras([])
    setPreviousSection()
  }

  useEffect(()=>{
    //console.log(props.genre)
    if (genre.Hiphop && !genre.Pop && !genre.Rnb){
      setEDMIsValid(false)
    }
    if (genre.Hiphop && genre.Pop && !genre.Rnb){
      setEDMIsValid(false)
    }
    if (genre.Hiphop && !genre.Pop && genre.Rnb){
      setEDMIsValid(false)
    }
    if (!genre.Hiphop && !genre.Pop && genre.Rnb){
      setEDMIsValid(false)
    }
    else{
      //setEDMIsValid(true)
    }

  },[extrasSelections, genre])

 
  return (
    <div>
      <PreviousButtonContainer onClick={goToPreviousSection} style={{cursor: 'pointer'}}>   
        <FaAngleLeft/>  
      </PreviousButtonContainer>
      <ChoiceHeadingContainer>Extras</ChoiceHeadingContainer>
      <SelectionContainer>
        <div>
        {isEDMValid ? <Button name={'EDM'} 
            isSelected={extrasSelections.includes('EDM') } 
            onClick={() => clickedChoice("EDM") }>EDM Vibes</Button>:''}

          <Button name={'Latin'} 
            isSelected={extrasSelections.includes('Latin')} 
            onClick={() => clickedChoice("Latin") }>
            Latin Hits
          </Button>
          <Button 
            isSelected={extrasSelections.length === 0}
            onClick={() => setExtrasSelections([])}>None</Button>
        </div>
        
        <NextPageButton isValid={true} onClick={goToNextSection}>
        Next
      </NextPageButton>
      </SelectionContainer>
    </div>
  )
}

function ChooseEnergy(props) {
  const [energy, setEnergy] = useState("Chill")

  const goToNextSection = () => {
    let energyFriendly = ''
    if (energy === 'Chill') {
      energyFriendly = "Slow"
    }
    if (energy === 'Medium') {
      energyFriendly = "Med"
    }
    if (energy === 'Intense') {
      energyFriendly = "Fast"
    }
    if (energy === 'IDGAF') {
      energyFriendly = "Mix"
    }

    props.setEnergy(energyFriendly)
    props.goToNextSection()
  }
  return (
    <div>
      <PreviousButtonContainer 
     onClick={props.gotToPreviousSection} style={{cursor: 'pointer'}}>   <FaAngleLeft/>  </PreviousButtonContainer>
      <ChoiceHeadingContainer>Energy</ChoiceHeadingContainer>
      <EnergySliderContainer>
      <CircularSlider
        width="250"
        label=" "
        labelColor="black"
        hideKnob={true}
        progressColorFrom="#ffe0d4"
        progressColorTo="#D34F1D"
        progressSize={26}
        trackColor="#DCDCDC"
        trackSize={26}
        data={["Chill", "Medium", "Intense", "IDGAF"]} //...
        valueFontSize="34px"
        dataIndex={0}
        verticalOffset="0.5rem"
        onChange={value => { setEnergy(value); }}
      />
       <NextPageButton isValid={true} onClick={() => {goToNextSection()}}>
        Next
      </NextPageButton>
      </EnergySliderContainer>
    </div>
  )
}

function Advanced(props) {
  const duration = "Both"

  useEffect(()=> {
    props.setDuration(duration)
  })

  const goToPlayer = () => {
    props.goToPlayer()
  }

  const goToNextSection = () => {
    props.goToNextSection()
  }

  return (
    <div>
      <PreviousButtonContainer onClick={props.gotToPreviousSection} style={{cursor: 'pointer'}}>   
        <FaAngleLeft/>  
      </PreviousButtonContainer>
      <ChoiceHeadingContainer>Advanced Options</ChoiceHeadingContainer>
      <SelectionContainer>
        <Button name={'yes'} onClick={goToNextSection}>Yes </Button>
        <Button name={'skip'} onClick={goToPlayer}>Skip for now</Button>
      </SelectionContainer>
    </div>
  )
}

function AdvancedMenu(props) {
  const [duration, setDuration] = useState('Both')
  const [depth, setDepth] = useState('Default')
  const [allValid, setAllValid] = useState(false)

  useEffect(()=> {
    if (duration !== ''){
      setAllValid(true)
    }
  }, [duration])

  useEffect(()=>{
    props.setDuration(duration)
    props.setDepth(depth)
  })

  const goToPlayer = () => {
    if (allValid) {
      props.goToPlayer()
    }
  }

  return(
    <div>
      <PreviousButtonContainer onClick={props.gotToPreviousSection} style={{cursor: 'pointer'}}>   
        <FaAngleLeft/>  
      </PreviousButtonContainer>
      <ChoiceHeadingContainer>Advanced Options</ChoiceHeadingContainer>
      <AdvancedContainer>
        <div>
          
        <AccordianDropdownTextWrap>
       
          Typical song duration in mix
        </AccordianDropdownTextWrap>
        <ButtonSmall name={'Short'} isSelected={duration === 'Short'} 
          onClick={() => setDuration('Short')}>Short </ButtonSmall>
        <ButtonSmall name={'Long'} isSelected={duration === 'Long'} 
        onClick={() => setDuration('Long')} >Long</ButtonSmall>
        <ButtonSmall name={'Both'} isSelected={duration === 'Both'} 
          onClick={() => setDuration('Both')}>Both</ButtonSmall>
          <StyledSlot3></StyledSlot3>
          <AccordianDropdownTextWrap>
          Depth
        </AccordianDropdownTextWrap>
        {props.energy === "Mix" ? <div><ButtonSmall name={'Low'} isSelected={depth === 'Low'} 
          onClick={() => setDepth('Low')}>Low </ButtonSmall>
        <ButtonSmall name={'Default'} isSelected={depth === 'Default'} 
        onClick={() => setDepth('Default')} >Default</ButtonSmall>
        <ButtonSmall name={'High'} isSelected={depth === 'High'} 
          onClick={() => setDepth('High')}>High</ButtonSmall></div>:
          <ButtonSmall name={'Default'} isSelected={depth === 'Default'} 
        onClick={() => setDepth('Default')} >Default</ButtonSmall>
          }
          <StyledSlot3></StyledSlot3>
          <AccordianDropdownTextWrap>
          Mix length (coming soon)
        </AccordianDropdownTextWrap>
        <ButtonSmall >30 min</ButtonSmall>
        <ButtonSmall >60 min</ButtonSmall>
        <ButtonSmall >90 min</ButtonSmall>
          
        </div>
        <NextPageButton isValid={true} onClick={() => {goToPlayer()}}>
        Next
      </NextPageButton>
       
      </AdvancedContainer>
    </div>
    
  )
}

function ChoicesSection({setUserChoices, goToPlayerPage, goToMainMenu}) {
  const [currentChoiceSection, setCurrentChoiceSection] = useState('genre')
  const [genre, setGenre] = useState('')
  const [extras, setExtras] = useState([])
  const [energy, setEnergy] = useState('')
  const [duration, setDuration] = useState('')
  const [depth, setDepth] = useState('Default')

  const goToPlayer = () => {
    const choices = {
      'genre': genre,
      'extras': extras,
      'energy': energy,
      'duration': duration,
      'depth': depth
    }
    setUserChoices(choices)
    goToPlayerPage()
  }

  switch (currentChoiceSection) {
    case 'genre':
      return(
        <ChooseGenre 
          goToNextSection={()=>{setCurrentChoiceSection('extras')}}
          setGenre={setGenre}
          gotToPreviousSection={() => { goToMainMenu() }}/>
          
      )
    case 'extras':
      return (
        <ChooseExtras 
          setNextSection={() => { setCurrentChoiceSection('energy') }}
          setPreviousSection={() => { setCurrentChoiceSection('genre') }}
          genre={genre}
          setExtras={setExtras}/>
      )
    case 'energy':
      return (
        <ChooseEnergy
          goToNextSection={() => { setCurrentChoiceSection('advanced') }}
          gotToPreviousSection={() => { setCurrentChoiceSection('extras') }}
          setEnergy={setEnergy}/>
      )
    case 'advanced':
      return (
        <Advanced 
          goToNextSection={() => { setCurrentChoiceSection('advancedMenu') }}
          gotToPreviousSection={() => { setCurrentChoiceSection('energy') }}
          setDuration={setDuration}
          goToPlayer={goToPlayer}/>
      )
    case 'advancedMenu':
      return(
        <AdvancedMenu
          gotToPreviousSection={() => { setCurrentChoiceSection('advanced') }}
          setDuration={setDuration}
          setDepth={setDepth}
          goToPlayer={goToPlayer}
          energy={energy}
        />
      )
    default:
      return(
        <div>no choices case!</div>
      )
  }
}

export default ChoicesSection