import { SkipContainer, SkipText, BtnMaxPremium, MaxPremiumText, MaxPremiumSubText, BlockContent, BlockContainer, BlockWrapper, BlockBgd, BlockHeader, UpgradeHeaderTextBox, BlockTextHeader, SettingsContent} from '../components/AppComponents/appElements';
import { gql, useMutation } from '@apollo/client';

import ErrorPage from './errorPage';
import LoadingComponent from '../components/LoadingComponent';

const SUBSCRIBE_TO_PREMIUM = gql`
  mutation SubscribeToPremium {
    createStripeCheckoutSessionPremium{
      urlSession
    }
  }
`;

function TooManyMixesPage ({goToMainMenu}) {

	const [subscribeToPremium, { data, loading, error }] = useMutation(SUBSCRIBE_TO_PREMIUM, { errorPolicy: 'all' })

	if (data) {
		const premium_link = data.createStripeCheckoutSessionPremium.urlSession
		const openPremiumSite = () => {
			// Browser.open({ url: premium_link });
			window.location.href = premium_link
		};
		if (premium_link !== ''){
			//window.location.href = premium_link
			openPremiumSite()
		}
		//return(
		//	<OpenedStripeURL backToSettingsMenu={backToSettingsMenu}/>
		//)
	}

	if (loading) {
		return(
			<SettingsContent>
				<LoadingComponent/>
			</SettingsContent>
		)
	}

	//if (cancelPage){
	//	return (
	//		<CancelPage setClickedChangePlan={setClickedChangePlan}/>
	//	)
	//}
	if (error) {
		return(
			<ErrorPage/>
		)
	}
	
	return (
		<BlockContainer>
			<BlockWrapper>
				<BlockBgd/>
				<BlockContent>
					<BlockHeader>
						<UpgradeHeaderTextBox>
							Upgrade now!	
						</UpgradeHeaderTextBox>					
					</BlockHeader>
					<BlockTextHeader>
						You have used all your free minutes for the two week period.
					</BlockTextHeader>
					<BlockTextHeader>
						<BtnMaxPremium onClick={subscribeToPremium}>
      				<MaxPremiumText> Premium Plan </MaxPremiumText>
      				<MaxPremiumSubText> Unlimited mixes, no ads </MaxPremiumSubText>
    				</BtnMaxPremium>
					</BlockTextHeader>
					<SkipContainer>
						<SkipText onClick={goToMainMenu} >
							Skip	
						</SkipText>
					</SkipContainer>
				</BlockContent>
			</BlockWrapper>
		</BlockContainer>
	)
}

export default TooManyMixesPage