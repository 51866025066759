import styled from 'styled-components'

export const PlayContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items:center;
	flex-direction: column;
  	padding-bottom:1.5rem;
	margin-top:160px;
	
	z-index: 2;
`

export const SettingsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items:center;
	flex-direction: column;
  	padding-bottom:1.5rem;
	margin-top:50px;
	z-index: 2;
`

export const BtnSettingsContainer = styled.button`
	border: none;
	background-color: Transparent;

	margin:10px;
	z-index: 2;
	color: #393939;

	font-family: 'Inter';
	font-size: 16.5px;

	font-Weight: bold;

	cursor: pointer;
	
	&:hover{
		opacity:1;
		color:#ECD4CA;
		transition:0.3s ease-out;
	}
	
	
`

export const BtnPremium = styled.button`
color: white;
border:none;
cursor: pointer;
border-radius: 30px;
background:#D34F1D;
padding: 8px 0px;
margin: 1em 0.5em;
width: 200px;
font-size: 13px;
  font-weight: 600;
  margin-top:45px;
  text-align:center;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

`

export const PremiumText = styled.div`
color: white;

font-size: 13px;
  font-weight: 600;
  text-align:center;

`

export const PremiumSubText = styled.div`
color: white;
margin: 4px;
font-size: 9.5px;
  font-weight: 600;
  text-align:center;

`
