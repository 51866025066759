import { gql, useMutation } from '@apollo/client';

import LoadingPage from './loadingPage';
import {CancelButton, ChangePlanText } from '../components/AppComponents/appElements';
import { CreateAccountHeader, CredentialsContainer } from '../components/LogInSection/logInElements';
import ErrorPage from './errorPage';


const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelStripeSubscription {
      success
    }
  }
`;

function CancelPage ({setClickedChangePlan}){

  const [cancelSubscriptionFunction, { data, loading, error }] = useMutation(CANCEL_SUBSCRIPTION, { errorPolicy: 'all' })

  const clickedCancel = () => {
    setClickedChangePlan(true)
    cancelSubscriptionFunction()
  }

  if (loading) {
    return(
      <LoadingPage/>
    )
  }
  if (error) {
    return(
      <ErrorPage/>
    )
  }

  if (data) {
    //console.log(data.cancelStripeSubscription)
    if (data.cancelStripeSubscription.success === 'true') {
      return (
        <CredentialsContainer>
          <CreateAccountHeader>
            You've succesfully canceled your subscription.
            Your experience will still be PREMIUM until the end of the period.
          </CreateAccountHeader>
        </CredentialsContainer>
      )
    }
  }

  return (
		<CredentialsContainer>
		  <CreateAccountHeader>
        Are you sure you want to cancel?
      </CreateAccountHeader>
      <CancelButton onClick={clickedCancel}>
		    <ChangePlanText>
			    Cancel
		    </ChangePlanText>
		  </CancelButton>
    </CredentialsContainer>
  )
}

export default CancelPage