import OrangeOnClearLogo from '../../images/Smoothi Clear Color.svg';
import {SmoothiLogo} from "./WebLogoElements";

//import { Capacitor } from '@capacitor/core';
//if (Capacitor.getPlatform() === 'web') {
function WebLogo (){
  
  return (
    
    <SmoothiLogo src={OrangeOnClearLogo}/>
   
  )
}


export default WebLogo
