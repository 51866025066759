import {PreviewHeaderLeft,PreviewSongTitle,PreviewSongArtist,PreviewTitle,PreviewContainer, PreviewWrapper, PreviewMenu, PreviewHeader, PreviewImage, PreviewHeaderTextBox, PreviewMixListContainer, PreviewMixListHeader, PreviewPlayButton, PreviewMixListItem, StyledSlot3, PreviewPlayIcon } from "./PreviewElements";
import {PreviousButtonContainer} from "../AppComponents/appElements";
import { FaAngleLeft} from 'react-icons/fa'



function PreviewSection(props) {

	const mixList = props.currentSlice.songPreviewPlaylist?.map((e, index) => {
		return (
			<div key={index}>
				<PreviewMixListItem>
					<PreviewSongTitle>
					{e.songTitle}
					
					</PreviewSongTitle>
					<PreviewSongArtist>
					{e.artist}
					</PreviewSongArtist>
				</PreviewMixListItem>
				<StyledSlot3 />
			</div>)
	})

	

	const clickPlay = () => {
		const userPreview = {
			'mixID': props.currentSlice.mixId, 
			'sliceID': props.currentSlice.slice_id,
			'title': props.currentSlice.slice_title
		}
		props.setUserPreview(userPreview)
		props.goToPlayer()
	}
	
  

	return (
		<PreviewContainer>
			<PreviousButtonContainer 
     onClick={props.goToMainMenu} style={{cursor: 'pointer'}}>   <FaAngleLeft/>  
	 </PreviousButtonContainer>
		
		<PreviewWrapper>
		
		<PreviewMenu>
		
		
		<PreviewHeader>
      <PreviewHeaderLeft>
      <PreviewTitle>
		{props.currentSlice.slice_title} 
		
		</PreviewTitle>
		<PreviewHeaderTextBox>
				Mix Overview
				
		</PreviewHeaderTextBox>
		<PreviewPlayButton onClick={clickPlay}> 
					<PreviewPlayIcon /> 
				</PreviewPlayButton>
        </PreviewHeaderLeft>
			
		<PreviewImage background={props.currentSlice.picture} ></PreviewImage>
			
			</PreviewHeader>
			
			
			<PreviewMixListContainer>
			<PreviewMixListHeader>
				Songs in this mix include:
				
			</PreviewMixListHeader>	
			{mixList}
		</PreviewMixListContainer>
			
			</PreviewMenu>
			</PreviewWrapper>
			
			</PreviewContainer>
	)
}

export default PreviewSection