import { FaBars } from "react-icons/fa";
import React from "react";
import {NavOptContainer,BtnNavOptContainer,MobileIcon, Nav, NavbarContainer,LogoBg,StyledSlot} from './NavbarElements'
//https://www.youtube.com/watch?v=Nl54MJDR2p8
import Logo from '../../images/smoothi-logo-white-on-clear.svg'

function Navbar (props){
  return (
    
    <Nav>
      <NavbarContainer>
        <LogoBg src={Logo}/>
        
        <NavOptContainer>
          <BtnNavOptContainer>
          {props.onMenu ? <p onClick={props.goToMainMenu} style={{cursor: 'pointer'}}>MENU</p> :''}
          
          </BtnNavOptContainer>
          
          <BtnNavOptContainer>
            {props.isLoggedIn ? <p onClick={props.logOutClick} style={{cursor: 'pointer'}}>LOG OUT</p> :''}
          </BtnNavOptContainer>
        </NavOptContainer>
        
        <MobileIcon onClick={props.toggle} isLanding={props.isLanding}>
          <FaBars/>
        </MobileIcon>

      </NavbarContainer>
      <StyledSlot></StyledSlot>
    </Nav>
   
  )
}

export default Navbar
