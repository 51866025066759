import { useEffect, useState, useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { AudioContext } from '../context/AudioContext';
import LoadingPage from './loadingPage';
import ErrorPage from './errorPage';
import TooManyMixesPage from './tooManyMixesPage';

const GET_MIX_COUNT_VALID = gql`
	query GetMixRequestCountValid{
	  requestCountValid
	}
`

const GET_MIX_REQUEST_FROM_CHOICES = gql`
  query GetMixFromChoices($choices: JSONString!) {
		mixRequest(choices: $choices) {
			id
			mix {
				mixId
				mixLiked
				url
				genre
				songPlaylist {
					songTitle
					artist
				}
				timesList {
					startTime
				}
				pictures
			}
		}
  }
`;

const GET_MIX_REQUEST_FROM_ID_AND_SLICE = gql`
  query GetMixRequestFromIdAndSlice($mixId: String!, $choicesId: String!) {
		mixRequestFromIdAndSlice(mixId: $mixId, choicesId: $choicesId){
			id
			mix{
				mixId
				mixLiked
				url
				songPlaylist {
					songTitle
					artist
				}
				timesList {
					startTime
				}
				pictures
			}
		}
  }
`;

//function TooManyRequests() {
	//return(
		//<div>
			//<p style={{color:"white"}}>Too Many Mixes Today 🤷</p>
		//</div>
	//)
//}

function ChoicesComponent({userChoices, setMix, goToMainMenu, goToMusicPlayer, setMixLiked, setSongUrl, setCurrentSong, resetPlayingValues, pressedPause}) {
	const choices = JSON.stringify(userChoices)
	const {setTitle, setMixRequestId} = useContext(AudioContext)

	const { loading, error, data } = useQuery(GET_MIX_REQUEST_FROM_CHOICES, {
		variables: { choices },
	});

	useEffect(()=>{
		if (data){
			pressedPause()
			setMixRequestId(data.mixRequest.id)
			setMix(data.mixRequest.mix)
			setSongUrl(data.mixRequest.mix.url)
			setTitle(data.mixRequest.mix.genre)
			setMixLiked(data.mixRequest.mix.mixLiked)
			setCurrentSong(data.mixRequest.mix.songPlaylist[0])
			resetPlayingValues()
			goToMusicPlayer()
		}
	}, [data, pressedPause, setMixRequestId, setMix, setSongUrl, setTitle, setMixLiked, setCurrentSong, resetPlayingValues, goToMusicPlayer])

	if (loading) {
		return(
			<LoadingPage/>
		)
	}
	else if (error) {
		if (String(error) === 'ApolloError: Too many minutes played') {
			return (
				<TooManyMixesPage goToMainMenu={goToMainMenu}/>
			)
		}
		return (
			<div>
				<p>error </p>
			</div>
		)
	}
	else {
		return <div/>
	}
}

function SliceComponent({userPreview, setMix, goToMainMenu, goToMusicPlayer, setMixLiked, setSongUrl, setCurrentSong, resetPlayingValues, pressedPause}) {
	const mixId = userPreview.mixID
	const choicesId = userPreview.sliceID
	const {setTitle, setMixRequestId} = useContext(AudioContext)

	const { loading, error, data } = useQuery(GET_MIX_REQUEST_FROM_ID_AND_SLICE, {
		variables: { mixId, choicesId },
	});

	useEffect(()=>{
		setTitle(userPreview.title)
	}, [setTitle, userPreview.title])

	useEffect(()=>{
		if (data){
			pressedPause()
			setMixRequestId(data.mixRequestFromIdAndSlice.id)
			setMix(data.mixRequestFromIdAndSlice.mix)
			setSongUrl(data.mixRequestFromIdAndSlice.mix.url)
			setMixLiked(data.mixRequestFromIdAndSlice.mix.mixLiked)
			setCurrentSong(data.mixRequestFromIdAndSlice.mix.songPlaylist[0])
			resetPlayingValues()
			goToMusicPlayer()
		}
	}, [data, pressedPause, setMixRequestId, setMix, setSongUrl, setMixLiked, setCurrentSong, resetPlayingValues, goToMusicPlayer,goToMainMenu])

	if (loading) {
		return (
			<LoadingPage/>
		)
	}
	else if (error) {
		if (String(error) === 'ApolloError: Too many minutes played') {
			return (
				<TooManyMixesPage goToMainMenu={goToMainMenu}/>
			)
		}
		return (
			<div>
				<p>error </p>
			</div>
		)
	}
	else {
		return <div/>
	}
}

function PlayerPage({userChoices, setMix, setSongUrl, userPreview, userSlice, goToMainMenu, goToMusicPlayer, setMixLiked, setCurrentSong, userSubscribed, goToTooManyRequests, resetPlayingValues, pressedPause}) {
	const [userInput, setUserInput] = useState('')

	const { loading, error, data } = useQuery(GET_MIX_COUNT_VALID)

	useEffect(()=>{
		if (!userSubscribed) {
			if (data) {
					if (!data.requestCountValid) {
						goToTooManyRequests()
					}
			}
		}
	}, [data, goToTooManyRequests, userSubscribed])

	useEffect(()=>{
		if (JSON.stringify(userChoices) !== '{}'){
			setUserInput('choices')
		}
		else if (userSlice !== '') {
			setUserInput('preview')
		}
	}, [userChoices, userSlice])

	if (loading) {
		return(
			<LoadingPage/>
		)
	}

	if (error) {
		return (
			<ErrorPage/>
		)
	}

	switch (userInput){
		case 'choices':
			return(
				<ChoicesComponent userChoices={userChoices} goToMusicPlayer={goToMusicPlayer} goToMainMenu={goToMainMenu} 
					setMixLiked={setMixLiked} setMix={setMix} setSongUrl={setSongUrl}
					setCurrentSong={setCurrentSong}
					resetPlayingValues={resetPlayingValues}
					pressedPause={pressedPause}/>
			)
		case 'preview':
			return (
				<SliceComponent userPreview={userPreview} goToMusicPlayer={goToMusicPlayer} goToMainMenu={goToMainMenu}
					setMixLiked={setMixLiked} setMix={setMix} setSongUrl={setSongUrl}
					setCurrentSong={setCurrentSong}
					resetPlayingValues={resetPlayingValues}
					pressedPause={pressedPause}/>
			)
		default:
			return(
				<div>
					No choices
				</div>
			)
	}
}

export default PlayerPage