import styled from 'styled-components'
import { IconContext } from "react-icons";

export const Button = styled.button`
  color:#393939;
  border: 2px solid black;
  border-color:#393939;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 600;
  
  background: ${({ isSelected }) => (isSelected ? 'rgb(211, 79, 29, 0.7 )' : 'rgb(0, 0, 0, 0)')};
  color: ${({ isSelected }) => (isSelected ? 'white' : '#393939')};
  border-color: ${({ isSelected }) => (isSelected ? 'rgb(211, 79, 29, 0.8 )' : '#393939')};


  padding: 15px 25px;
  
  margin: .9em 1.5em;
  width: 250px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 400px) {
		width: 220px;
`;

export const ButtonSmall = styled.button`
  color: #39393;
  border: 2px solid black;
  border-color:#393939;
  border-radius: 30px;
  font-size: 11px;
  background: ${({ isSelected }) => (isSelected ? 'rgb(211, 79, 29, 0.7 )' : 'rgb(0, 0, 0, 0)')};
  color: ${({ isSelected }) => (isSelected ? 'white' : '#393939')};
  border-color: ${({ isSelected }) => (isSelected ? 'rgb(211, 79, 29, 0.8 )' : '#393939')};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

  padding: 13px 0px;
  margin: 1em 0.5em;
  width: 70px;

  @media screen and (max-height: 700px) {
		padding: 11px 0px;


`;



export const NextPageButton = styled.button`
color: white;
border:none;
border-radius: 30px;
background:#D34F1D;
opacity: ${({ isValid }) => (isValid ? 1 : 0.3)};

padding: 14px 0px;
margin: 1em 0.5em;
width: 130px;
font-size: 15px;
font-weight: 600;
margin-top:30px;
box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
margin-bottom:165px;



@media screen and (max-height: 600px) {
  margin-top:10px;

`


export const ChoiceHeadingContainer = styled.div`
	color: #393939;
	padding-top: 3rem;
  padding-bottom: 1rem;
	font-size: 18px;
  font-weight:bold;
  text-align:center;
  font-family: 'Inter';
  margin-top:45px;

  @media screen and (max-height: 700px) {
		margin-top:10px;

  @media screen and (max-height: 600px) {
		margin-top:0px;
  
`



const IconProvider = ({ className, children }) => <IconContext.Provider value={{ className }}>{children}</IconContext.Provider>;

export const ArrowContainer = styled(IconProvider)`
	color: ${({ isValid }) => (isValid ? "#D34F1D" : '')};
	height: 5rem;
  font-size: 3rem;

  //&:hover{
    //opacity:1;
    //color:${({ isValid }) => (isValid ? '#D34F1D' : '')};
    //transition:0.3s ease-out;
  //}
`;



export const SelectionContainer = styled.div`
  z-index: 2;
	width:230px;
	display: flex;
	flex-direction: column;
	text-align: center;
  align-items: center;
  
`

export const AdvancedContainer = styled.div`
  z-index: 2;
	width:250px;
	display: flex;
	flex-direction: column;
	text-align: center;
  align-items: center;
  
`

export const EnergySliderContainer = styled.div`
  z-index: 2;
	width:230px;
	display: flex;
	flex-direction: column;
	text-align: center;
  align-items: center;
  margin-top:25px;

  @media screen and (max-height: 700px) {
		margin-top:10px;
  
`

export const AdvMenuContainer = styled.div`
	text-align: left;
`


export const BottomLines = styled(IconProvider)`

	font-size: 3rem;
	color: ${({ isMenu }) => (isMenu ? 'white' : '#b9b0b0')};

	display: inline-block;
`;
export const BottomContainer = styled.div`
	text-align: center;
	display: flex;
  align-items: center;
  justify-content: center;
	

`


export const AccordianContainer=styled.div`

width:275px;

  @media screen and (max-width:768px) {
    width:250px;
}

@media screen and (max-width: 480px) {
	width:200px;
}

`



export const AccordianTextWrap=styled.div`
color:black;
cursor:pointer;
h1{
	
	font-size:0.85rem;
}
display:flex;
align-items: center;
vertical-align: middle;
flex-direction:row;
justify-content:space-between;

`



export const ComingSoonContainer=styled.div`



color:black;
font-size:0.47rem;
justify-content: center;
display: grid;
align-items: center;


`

export const Dropdown=styled.div``

export const AccordianDropdownTextWrap=styled.div`
color: #393939;
text-align: center;
font-size: 12px;
font-family: Inter;
margin-top:10px;
margin-bottom:5px;
font-weight: 600;



`

export const StyledSlot3 = styled.div`
	flex: 1;
	align-content: center;
	justify-content: center;
	
  width:240px;
	height: 0.75px;
	background-color: #9C9797;
	top: 0;
	z-index: 11;
	opacity:0.45;
	margin-bottom:3px;
	margin-top: 3px ;

`;