//import { useContext, useState } from 'react';
import { SubSettingsContent,PreviousButtonContainer,TermsContent} from '../components/AppComponents/appElements';
import { FaAngleLeft } from 'react-icons/fa';

function PrivacyInAppPage({backToSettingsMenu}){

		return (
				<SubSettingsContent>
					
					
						<PreviousButtonContainer>
							<FaAngleLeft onClick={backToSettingsMenu} />
						</PreviousButtonContainer>
						<TermsContent>
				<iframe 
					title="Terms and Conditions" 
					src="https://app.termly.io/policy-viewer/policy.html?policyUUID=16632940-1291-4534-8003-a24cec53da83"
					style={{background: "#EFEBE5", borderColor:"black", height:"400px", width:"100%"}}/>
				<br/>
				</TermsContent>

				</SubSettingsContent>
		)
}

export default PrivacyInAppPage