//import { useContext, useState } from 'react';
import { SubSettingsContent,PreviousButtonContainer,TermsContent} from '../components/AppComponents/appElements';
import { FaAngleLeft } from 'react-icons/fa';

function TermsInAppPage({backToSettingsMenu}){
	
		return (
			<SubSettingsContent>
				<PreviousButtonContainer>
					<FaAngleLeft onClick={backToSettingsMenu} />
				</PreviousButtonContainer>
				<TermsContent>
					<iframe 
						title="Terms and Conditions" 
						src="https://app.termly.io/policy-viewer/policy.html?policyUUID=f0e4597f-ed28-4832-b5bf-177575a226ae"
						style={{background: "#EFEBE5", borderColor:"black", height:"400px", width:"100%"}}/>
					<br/>
				</TermsContent>
			</SubSettingsContent>
		)
}

export default TermsInAppPage