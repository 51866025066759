import {PlayerHeader,PlayerContent, PlayerSubTitle, PlayerTitle, PlayerTimer, PlayerSong,  PlayerArtist,PlayerImgBgSW,PlayerImgBgSE,PlayerImgBgNE,PlayerImgBgNW,PlayerBg,PlayContainer, PauseBtn,PlayBtn } from "./PlayerElements";
import { useContext } from 'react'
import { CircularProgressbarWithChildren} from 'react-circular-progressbar';
import { IoHeartOutline, IoHeart } from 'react-icons/io5'
import { AudioContext } from "../../context/AudioContext";


function time_convert(sec_num) {
  let total_seconds = Math.floor(sec_num)
  let hours = 0
  let minutes = Math.floor(total_seconds / 60);
  let seconds = total_seconds % 60;
  let time_string = ""
  if (minutes > 59) {
    hours = Math.floor(minutes / 60)
    minutes = minutes - 60 * hours 
    time_string = time_string + hours + ":"
    if (minutes < 10) {
      time_string = time_string + "0"
    }
  }
  if (seconds < 10) {
    time_string = time_string + minutes + ":0" + seconds
    return time_string;
  }
  else {
    time_string = time_string + minutes + ":" + seconds
    return time_string;
  }
}

function Heart({mixLiked, handleLikeClick, handleDeleteLikeClick}) {
	if (mixLiked) {
		return(<IoHeart onClick={handleDeleteLikeClick}/>)
	}
	else if (!mixLiked){
		return <IoHeartOutline onClick={handleLikeClick}/>
	}
  else {
    return <></>
  }
}

function Title ({mainChoice, currentTime, mixLiked, handleLikeClick, handleDeleteLikeClick}) {
  const timeStyled = time_convert(currentTime)

  return(
    <PlayerHeader>
      <PlayerTitle>
        { mainChoice } Mix
      </PlayerTitle>
      <div style={{ color: "#D34F1D", fontSize: "2rem" }}>
        <Heart mixLiked={mixLiked} handleLikeClick={handleLikeClick} handleDeleteLikeClick={handleDeleteLikeClick}/>
      </div>
      <PlayerTimer>
        {timeStyled}
      </PlayerTimer>
    </PlayerHeader>
  )
}

function GradientSVG() {
  const idtrail = "hello";
  const gradientTransform = `rotate(0)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <radialGradient id={idtrail} cx="50%" cy="51.4%" r="56.5%" fx="50%" fy="50%" gradientTransform={gradientTransform}>
          <stop offset="95%" stopColor="white" />
          <stop offset="100%" stopColor="#C3C3C3" />
        </radialGradient>
      </defs>
    </svg>
  );
}

function GradientSVG2() {
  const idCSS = "hi";
  const gradientTransform = `rotate(90)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="30.29%" stopColor="#ffd1bf" />
          <stop offset="69.56%" stopColor="#D34F1D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function Player ({audioRef, currentSong, mixLiked, currentTime, percentage, handleLikeClick, isPlaying, handleDeleteLikeClick, title}) {
  
  const {mix} = useContext(AudioContext)

  const Player_Bg_Img_1 = mix.pictures[0]
  const Player_Bg_Img_2 = mix.pictures[1]
  const Player_Bg_Img_3 = mix.pictures[2]
  const Player_Bg_Img_4 = mix.pictures[3]
  const mainChoice = title

  const audioInstance = audioRef.current

  const pressPlayerButton = () => {
    if (audioInstance.paused === false) {
      audioInstance.pause()
    }
    else {
      audioInstance.play()
    }
  }

  const idpath = "hello";

  const idCSS = "hi";
  
  return(
    <PlayerContent>
      <PlayerSubTitle>
        Now Playing
      </PlayerSubTitle>

      <Title mainChoice={mainChoice} currentTime={currentTime} mixLiked={mixLiked} handleLikeClick={handleLikeClick} handleDeleteLikeClick={handleDeleteLikeClick}/>
      <GradientSVG />
      <GradientSVG2 />
      
      <CircularProgressbarWithChildren value={percentage}  styles={{
            path: { stroke: `url(#${idCSS})`, height: "100%" },
            trail: {stroke: `url(#${idpath})`,},
            strokeLinecap: 'round',
            backgroundColor: 'rgba(255,255,255, 0)',
            // Text size
            textSize: '15px',
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,
          }}>
        <PlayerBg>
          <PlayerImgBgSW src={Player_Bg_Img_1}/>
          <PlayerImgBgSE src={Player_Bg_Img_2}/>
          <PlayerImgBgNE src={Player_Bg_Img_3}/>
          <PlayerImgBgNW src={Player_Bg_Img_4}/>
        </PlayerBg>
        <PlayContainer>
          {
            <div>
              <br />
              {isPlaying ? <PauseBtn onClick={pressPlayerButton} /> : <PlayBtn onClick={pressPlayerButton}/>}
              <br/>
            </div>
          }
        </PlayContainer>
      </CircularProgressbarWithChildren>
      
      
      <PlayerSong>{currentSong.songTitle}</PlayerSong>
      <PlayerArtist>{currentSong.artist}</PlayerArtist>

    </PlayerContent>
  )
}

export default Player