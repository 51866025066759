import { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client';
import { ImageBg, ForgotContainer,ForgotPasswordHeader, ForgotContent, PreviousButtonContainer, ForgotPasswordContainer } from '../components/AppComponents/appElements';
import { ResetPassButton, CreateAccountHeader, CredentialsContainer, Input } from '../components/LogInSection/logInElements';
//import { FiArrowRightCircle } from 'react-icons/fi';
import { FaAngleLeft } from 'react-icons/fa'
import LoadingComponent from '../components/LoadingComponent';
import ErrorPage from './errorPage';

const FORGOT_PASSWORD = gql`
  mutation SendResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email){
      success
      errors
    }
  }
`;

const validateEmail = (email) => {
	return email.match(
		/^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
};

function DataComponent({data, goToMainPage}) {
	const successMessage = 'Please check your email for a link!'
	if (data.sendPasswordResetEmail.success) {
		return(
			<div>
				<p style={{marginTop:"-25px"}}>
					<CreateAccountHeader>
							Success!
					</CreateAccountHeader>
					{successMessage}
					</p>
						
					</div>
		)
	}
	else {
		return(

			<div>
				<p style={{marginTop:"-25px"}}></p>
			
						<CreateAccountHeader>
						{data.sendPasswordResetEmail.errors.nonFieldErrors[0].message}
						 </CreateAccountHeader>
						
						</div>		
		)
	}
}

function ForgotFormComponent({goToMainPage}) {
	const [email, setEmail] = useState('')
	const [clickedButton, setClickedButton] = useState(false)
	const [emailError, setEmailError] = useState(true)
	const emailErrorString = 'Please enter a valid email.'
	const resetError = 'There seems to be an issue.'

	const [sendResetFunction, { data, loading, error }] = useMutation(FORGOT_PASSWORD, { errorPolicy: 'all' })

	useEffect(()=>{
		if (validateEmail(email)) {
			setEmailError(false)
		}
		else (
			setEmailError(true)
		)
	}, [email])

	const handleEmailChange = (event) => {
		setEmail(event.target.value)
	}

	const handleClick = () => {
		setClickedButton(true)
		if (email && !emailError) {
			sendResetFunction({ variables: { email: email} })
		}
	}

	if (loading) {
		return(
			<p style={{marginTop:"-90px"}}>
			
			<LoadingComponent/>
			</p>
				
		)
	} else if (error) {
		return(
			<p style={{marginTop:"-150px"}}>
			<ErrorPage appError={resetError}/>
			</p>
		)
	} else if (data) {
		return(
			<DataComponent data={data} goToMainPage={goToMainPage}/>
		)
	}
	
	return(
		<CredentialsContainer>
			
			<Input placeholder={'Email'} onChange={handleEmailChange}/>
			<p style={{ color: "#A9A9A9", fontSize: "11px" }}> 
				{(clickedButton & emailError) ? emailErrorString:""} 
			</p>
			
			<ResetPassButton onClick={handleClick} style={{cursor: 'pointer'}}>
				Send Reset Email 
			</ResetPassButton>
		</CredentialsContainer>
	)
}

function ForgotPage({logOut}) {
	return(
		<ForgotContainer>
			<ImageBg/>
			<ForgotContent>
			<div>
				<PreviousButtonContainer onClick={logOut} style={{cursor: 'pointer'}}>   
					<FaAngleLeft />  
				</PreviousButtonContainer>
				<ForgotPasswordHeader >
  			  Reset your password
				</ForgotPasswordHeader>
				<ForgotPasswordContainer>
				<ForgotFormComponent goToMainPage={logOut}/>
				</ForgotPasswordContainer>
				</div>
			</ForgotContent>
		</ForgotContainer>
	)
}

export default ForgotPage