import { LogoBg } from "../AppComponents/appElements"
import Logo from '../../images/smoothi-logo-circle.svg'

function LoadingComponent() {
  return(
    <div>
      <LogoBg src={Logo} style={{width:"90%", marginBottom:"-10px", marginTop:"120px"}}/>
      <p style={{color:"#D34F1D", fontFamily: 'Inter'}}>Loading ...</p>
    </div>
  )
}

export default LoadingComponent