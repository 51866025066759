import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SkipContainer,SkipText,BtnMaxPremium, BlockContent,BlockContainer, BlockWrapper, BlockBgd, BlockHeader, VerifyHeaderTextBox, BlockTextHeader} from '../components/AppComponents/appElements';


const RESEND_ACTIVATION_EMAIL = gql`
  mutation ($email: String!){
    resendActivationEmail(email: $email){
			errors
			success
    }
  }

  
`


function VerifyEmailPage({logOutClick, email}) {
  const [resendActivationEmail, { data, loading, error }] = useMutation(RESEND_ACTIVATION_EMAIL, { errorPolicy: 'all' })
  const [activationEmailSent, setActivationEmailSent] = useState(false)

  const handleSendEmailClick = () => {
    resendActivationEmail({ variables: { email: email} })
  }

  useEffect(()=>{
    if (data) {
      if (data.resendActivationEmail.success) {
        setActivationEmailSent(true)
      }
    }
  }, [data, loading, error])

  if (activationEmailSent) {
    return(
      <div>
      <p>Activation Email Sent!</p>
      <button onClick={logOutClick}>Log out!</button>
    </div>
    )
  }

  return(
    <BlockContainer >

			<BlockWrapper>
				<BlockBgd/>
	
					<BlockContent>
					
						<BlockHeader>
							
							
							<VerifyHeaderTextBox >
							You must verify your account to continue!
								
							</VerifyHeaderTextBox>
							
						</BlockHeader>
						
						
					
						<BlockTextHeader>
            Click to send activation email to {email}
								
						</BlockTextHeader>	
						
						
						
						<BlockTextHeader>
						<BtnMaxPremium style={{height:"40px"}}
       onClick={handleSendEmailClick}>Resend Email
      
      					
    					</BtnMaxPremium>
						
						</BlockTextHeader>
						

						
						<SkipContainer>
						<SkipText style={{marginTop:"14px"}} onClick={logOutClick}>Log out 
						</SkipText>
						</SkipContainer>
					</BlockContent>
			</BlockWrapper>
		</BlockContainer>
 
      

  )
}

export default VerifyEmailPage