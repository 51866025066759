import styled from 'styled-components'
import { FaPlay, FaPause} from "react-icons/fa"
import {AiOutlineHeart, AiFillHeart } from "react-icons/ai"


export const PlayerBarContainer = styled.div`
	z-index:999;
	//border: 9px solid red;
  	height: 60px;
	border-radius: 40px;
	border:none;
	font-size: 11px;
	color: #FAF8F8;
	background-color:#D34F1D;
	opacity:0.9;
	width: 100%;
	left: 0px;
	#right: 3px;
	position:fixed;
	bottom:97px;
	display: flex;
	flex-direction: bar;
	align-items:center;
	justify-content: center;
`

export const ArtistContainer = styled.div`
font-weight: bold;
font-size: 12px;
margin-right:10px;

@media screen and (max-width: 300px) {
	font-size: 11px;

  	
`

export const TitleContainer = styled.div`
font-weight: bold;
font-size: 16px;
margin-right:10px;

@media screen and (max-width: 300px) {
	font-size: 13px;

  	
`

export const PlayerBarContent = styled.div`
width: 88%;
display: flex;
	flex-direction: bar;
	align-items:center;
	align-items:center;
	justify-content: space-between;

`

export const MiniContentContainer = styled.div`

	display: flex;
	flex-direction: column;
	align-items:center;
	width:80%;
	height:45px;
	justify-content: center;
	font-size: 14px;
	//margin-left:30px;
	

	
`

export const PlayIcon = styled(FaPlay)`
	color: white;
	font-size:1.05rem;
	padding-left:0rem;
	margin-bottom:1px;
	
`

export const PauseIcon = styled(FaPause)`
	color: white;
	font-size:1.05rem;
	padding-left:0rem;
`

export const HeartIcon = styled(AiOutlineHeart)`
	margin-left:5px;
	margin-right:-4px;
	color: white;
	font-size:1.50rem;
	padding-left:0rem;
	margin-top:4px;
	
`

export const FullHeartIcon = styled(AiFillHeart)`
	margin-left:5px;
	margin-right:-4px;
	color: white;
	font-size:1.50rem;
	padding-left:0rem;
	margin-top:4px;
	
`

export const PlayButton = styled.button`

background-color:#D34F1D;
width:35px;
height:35px;
border:none;
margin-top:4px;
  
`;



