import { FiArrowRightCircle } from "react-icons/fi"
import { Link } from "react-router-dom"
import { ImageBg, SmoothiContainer } from "../components/AppComponents/appElements"
import { BtnCreate,SelectionContainer } from "../components/LogInSection/logInElements"
import Navbar from "../components/Navbar"

function PrivacyPage() {
  return(
		<div>
			<SmoothiContainer>
			<Navbar/>
				<ImageBg />
				<SelectionContainer>
					<div style={{ backgroundColor: "white",marginTop:"75px" }}>
						<iframe
							title="Privacy Policy"
							src="../privacy.html"
							style={{ border: "white", height: "350px" }} />
					</div>
					<br/>
					<Link to="/" style={{ textDecoration: "none" }}>
						<BtnCreate style={{ cursor: 'pointer' }}>
							Go to App <FiArrowRightCircle style={{ fontSize: "38px", margin: "7px", cursor: 'pointer' }} /> 
						</BtnCreate>
					</Link>
					</SelectionContainer>
			</SmoothiContainer>
		</div>
	)
}

export default PrivacyPage