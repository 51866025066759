import { OrangeError, ImageBg, SmoothiContainer,ExploreContent } from '../components/AppComponents/appElements';

function ErrorPage ({appError}) {
	return (
		<SmoothiContainer>
			<ImageBg />
			<ExploreContent>
				<OrangeError style={{width:"20%"}}/>
				<p style={{color:"#D34F1D", fontFamily: 'Inter'}}>{appError}</p>
			</ExploreContent>
		</SmoothiContainer>
	)
}

export default ErrorPage