import { ImageBg, SmoothiContainer, ExploreContent } from '../components/AppComponents/appElements';

function AccountDeletedPage() {
  const deletedMessage = 'Account deleted successfully!'
	return (
		<SmoothiContainer>
			<ImageBg />
			<ExploreContent>
				<p style={{color:"#D34F1D", fontFamily: 'Inter'}}>{deletedMessage}</p>
			</ExploreContent>
		</SmoothiContainer>
	)
}

export default AccountDeletedPage