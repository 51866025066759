import { useContext, useState } from "react"
import { gql, useMutation } from '@apollo/client';
import { Input, CreateAccountHeader, ResetPassButton } from "../components/LogInSection/logInElements"
import { ChangePasswordWrapper } from "../components/AppComponents/appElements"
import { UserContext } from "../context/UserContext";

const DELETE_ACCOUNT = gql`
  mutation deleteAccount($password: String!){
    deleteAccount(password: $password){
			errors
			success
    }
  }
`

function DeleteAccountPage() {
  const {setAccountDeleted} = useContext(UserContext)
  const [password, setPassword] = useState('')

  const [deleteAccountFunction, { data, loading, error }] = useMutation(DELETE_ACCOUNT)

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleDeleteAccount = () => {
    if (password) {
      deleteAccountFunction({ variables: { password: password} })
    }
  }
  if (error) {
    return(
      <p>Some error has happened</p> 
    )
  }
  else if (loading) {
    return(
      <p>Loading...</p> 
    )
  }
  else if (data) {
    if (data.deleteAccount.success){
      setAccountDeleted()
    }
    else if (!data.success && data.deleteAccount.errors) {
      return (
        <h4 style={{color:"black"}}>{data.deleteAccount.errors.nonFieldErrors[0].message}</h4>
      )
    }
  }
  
  return(
    <ChangePasswordWrapper>
      <CreateAccountHeader>
        Delete Account
      </CreateAccountHeader>
      <Input style={{margin:"0.3px"}}
						type="password" 
						placeholder={'Password'} 
						value={password} 
						onChange={handlePasswordChange} />
      <br/>
      <ResetPassButton onClick={handleDeleteAccount} style={{cursor: 'pointer'}}>
        Delete Account 
      </ResetPassButton>
    </ChangePasswordWrapper>
  )
}

export default DeleteAccountPage